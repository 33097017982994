import { QUERY } from '../../../gql-operations';

async function loadMessagesAroundMessage(
    messageOrDate,  // Can be a message object or Date object
    fetchMore,
    {
        contextLimit = 10,
        isTrash = false,
        scrollBehavior = 'smooth',
        scrollBlock = 'center',
        scrollDelay = 500,
        folderId  // Add folderId for date-based loading
    } = {}
) {
    try {
        const variables = {
            data: {
                folderId: messageOrDate.folder?.id || folderId,
                contextLimit,
                isTrash
            }
        };

        // Add either messageId or targetDate based on input type
        if (messageOrDate instanceof Date) {
            variables.data.targetDate = messageOrDate.toISOString();
        } else {
            variables.data.messageId = messageOrDate.id;
        }

        const result = await fetchMore({
            query: QUERY.GET_FOLDER_CONTENT,
            variables,
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult?.getFolderContent?.messages) {
                    return previousResult;
                }

                const newMessages = fetchMoreResult.getFolderContent.messages.messages;
                const targetMessageId = fetchMoreResult.getFolderContent.messages.targetMessageId;

                // Sort messages by date (newest first)
                newMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                // console.log('Context loading:', {
                //     targetMessageId,
                //     newMessagesCount: newMessages.length,
                //     newestMessage: newMessages[0]?.createdAt,
                //     oldestMessage: newMessages[newMessages.length - 1]?.createdAt,
                //     targetDate: messageOrDate instanceof Date ? messageOrDate : messageOrDate.createdAt
                // });

                 // Handle case where previousResult is undefined
                 const baseResult = previousResult || {
                    getFolderContent: {
                        messages: {
                            messages: [],
                            cursor: null,
                            __typename: fetchMoreResult.getFolderContent.messages.__typename
                        },
                        __typename: fetchMoreResult.getFolderContent.__typename
                    }
                };

                return {
                    getFolderContent: {
                        ...baseResult.getFolderContent,
                        messages: {
                            messages: newMessages,
                            cursor: fetchMoreResult.getFolderContent.messages.cursor,
                            targetMessageId,
                            __typename: baseResult.getFolderContent.messages.__typename
                        }
                    }
                };
            }
        });
        
        // Get the target message ID (either from input or first message)
        const targetId = messageOrDate instanceof Date
            ? result?.data?.getFolderContent?.messages?.messages[0]?.id
            : messageOrDate.id;

        // Use the same scrolling logic for both cases
        if (targetId) {
            setTimeout(() => {
                const element = document.getElementById(targetId);
                if (element) {
                    element.scrollIntoView({
                        behavior: scrollBehavior,
                        block: scrollBlock
                    });
                }
            }, scrollDelay);
        }


    } catch (error) {
        console.error('Error loading messages around reference:', error);
        throw error;
    }
}

export default loadMessagesAroundMessage;