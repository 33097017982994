import { FIND_FOLDERS } from './folder/find-folders';
import { GET_FOLDER_CONTENT } from './folder/get-folder-content';
import { GET_HOME_FOLDERS } from './folder/get-home-folders';
import { GET_TRASH_FOLDERS_GLOBAL } from './folder/get-trash-folders-global';

import { FILTER_MESSAGES } from './message/filter-messages';
import { FIND_MESSAGES } from './message/find-messages';
import { GET_CHECKBOX_MESSAGES_GLOBAL } from './message/get-checkbox-messages-global';
import { GET_MESSAGE } from './message/get-message';
import { GET_STARRED_MESSAGES_GLOBAL } from './message/get-starred-messages-global';
import { GET_TRASH_MESSAGES_GLOBAL } from './message/get-trash-messages-global';
import { GET_TRASH_MESSAGES_IN_FOLDER } from './message/get-trash-messages-in-folder';
import { DOWNLOAD_FILE } from './message/download-file';
import { GET_MESSAGES_AROUND } from './message/get-messages-around-message';

import { GET_ALL_USERS } from './user/get-all-users';
import { GET_ALL_ANALYTICS } from './user/get-all-analytics';
import { ME } from './user/me';
import { FIND_USERS } from './user/find-users';

import { GET_ACTIVE_PLANS } from './purchase/get-active-plans';
import { GET_USER_PURCHASES } from './purchase/get-user-purchases';

const QUERY = {
    GET_HOME_FOLDERS,
    GET_FOLDER_CONTENT,
    FIND_FOLDERS,
    GET_CHECKBOX_MESSAGES_GLOBAL,
    FIND_MESSAGES,
    GET_MESSAGE,
    FILTER_MESSAGES,
    GET_STARRED_MESSAGES_GLOBAL,
    GET_TRASH_FOLDERS_GLOBAL,
    GET_TRASH_MESSAGES_GLOBAL,
    GET_TRASH_MESSAGES_IN_FOLDER,
    DOWNLOAD_FILE,
    GET_ALL_USERS,
    GET_ALL_ANALYTICS,
    ME,
    FIND_USERS,
    GET_ACTIVE_PLANS,
    GET_USER_PURCHASES,
    GET_MESSAGES_AROUND
};

export default QUERY;
