const DateField = {
    CREATED_AT: 'createdAt',
    DELETED_AT: 'deletedAt',
    UPDATED_AT: 'updatedAt',
    LAST_VIEWED_AT: 'lastViewedAt'
};

const DEFAULT_SORT_FIELD = DateField.CREATED_AT;
const HISTORY_SORT_FIELD = DateField.LAST_VIEWED_AT;

module.exports = {
    DateField,
    DEFAULT_SORT_FIELD,
    HISTORY_SORT_FIELD
};