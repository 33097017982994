import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { IoIosStar, IoIosTime, IoIosAlbums } from 'react-icons/io';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { IoFileTray, IoTime } from 'react-icons/io5';
import { PALE_WHITE_8, PALE_WHITE_4, PALE_WHITE_2, PRIMARY_COLOR_4, PRIMARY_COLOR_8 } from '../appearance/Colors';

import { STARRED, CHECKBOXES, HIERARCHY, HISTORY } from '../util/specialFolderEnums';

import { AuthContext } from '../util/context';

const SpecialFolder = ({ onDrop, inputFolder, shortcutTitle, ...props }) => {
    const {
        state: { selectedFolder, homeFolder }
    } = React.useContext(AuthContext);

    const renderIcon = () => {
        if (inputFolder.id === HIERARCHY) {
            return <IoIosAlbums size={14} color={PALE_WHITE_8}></IoIosAlbums>;
        } else if (inputFolder.id === CHECKBOXES) {
            return <IoCheckmarkCircle size={14} color={PALE_WHITE_8}></IoCheckmarkCircle>;
        } else if (inputFolder.id === STARRED) {
            return <IoIosStar size={14} color={PALE_WHITE_8}></IoIosStar>;
        } else if (inputFolder.id === (homeFolder && homeFolder.id)) {
            return <IoFileTray size={14} color={PALE_WHITE_8}></IoFileTray>;
        } else if (inputFolder.id === HISTORY) {
            return <IoTime size={14} color={PALE_WHITE_8}></IoTime>;
        }
    };

    const renderTitle = () => {
        let title;
        if (inputFolder.id === HIERARCHY) {
            title = 'All';
        } else if (inputFolder.id === CHECKBOXES) {
            title = 'Tasks';
        } else if (inputFolder.id === STARRED) {
            title = 'Stars';
        } else if (inputFolder.id === (homeFolder && homeFolder.id)) {
            title = 'Inbox';
        } else if (inputFolder.id === HISTORY) {
            title = 'Recent'
        }
        return title;
    };

    return (
        <FolderContainer active={selectedFolder && selectedFolder.id === inputFolder.id} {...props} id={inputFolder.id}>
            <IconContainer>{renderIcon()}</IconContainer>
            <span
                style={{
                    width: '100%',
                    paddingTop: 6,
                    paddingBottom: 6
                }}
            >
                {renderTitle()}
            </span>
            {shortcutTitle ? (
                <>
                    <div style={{ flex: 1 }}></div>
                    <TextWrapper className="shortcut">
                        <span>{shortcutTitle}</span>
                    </TextWrapper>
                </>
            ) : null}
        </FolderContainer>
    );
};

const FolderContainer = styled.div`
    ${props =>
        props.active &&
        css`
            background-color: rgb(255, 255, 255, 0.13);
        `}
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: row;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 150px;
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        .shortcut {
            color: ${PALE_WHITE_4};
        }
    }
`;

const TextWrapper = styled.div`
    // padding: 2px;
    color: transparent;
    margin-left: 4px;
`;

const IconContainer = styled.div`
    display: inline-block;
    width: 18px;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
`;

export default SpecialFolder;
