import { gql } from 'apollo-boost';

// gql-operations.js
// In your frontend GraphQL query
export const GET_MESSAGES_AROUND = gql`
    query GetMessagesAround($data: GetMessagesAroundMessageInput!) {
        getMessagesAroundMessage(data: $data) {
            messages {
                id
                body
                folder {
                    id
                    title
                }
                image {
                    url
                    name
                    displayName
                    type
                    format
                    size
                    aspect_ratio
                    width
                }
                title
                owner
                file {
                    url
                    name
                    displayName
                    type
                    format
                    size
                    mime
                }
                createdAt
                updatedAt
                deletedAt
                cursor
                deletedCursor
                isCheckbox
                isChecked
                isStarred
                type {
                    type
                    format
                }
                metadata {
                    savedFrom {
                        type
                        device
                        deviceVersion
                        browser
                        browserVersion
                    }
                    updatedFrom {
                        type
                        device
                        deviceVersion
                        browser
                        browserVersion
                    }
                }
                urlMeta {
                    author
                    date
                    description
                    image
                    logo
                    publisher
                    title
                    url
                }
            }
            cursor
        }
    }
`;
