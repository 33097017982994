import React, { useState, useEffect } from 'react';

import Modal from './Modal';

import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { MUTATION } from '../../gql-operations';

import { useMutation } from '@apollo/react-hooks';

import SelectFolderComponent from '../SelectFolderComponent';

import useGetSubfolders from '../../hooks/useGetSubfolders';

import { AuthContext } from '../../util/context';
import {
    PRIMARY_COLOR,
    PRIMARY_COLOR_4,
    PRIMARY_COLOR_6,
    SECONDARY_COLOR,
    PRIMARY_COLOR_2,
    PRIMARY_COLOR_5,
    PALE_WHITE_5,
    PALE_WHITE_2,
    PALE_WHITE_4,
    WHITE,
    PALE_WHITE_6,
    PALE_WHITE_3
} from '../../appearance/Colors';

import styled from 'styled-components';

import { IoIosClose } from 'react-icons/io';
import customAnalytics from '../../util/custom-analytics';

function CreateFolderModal({ isOpen, closeModal, currentFolder, subfolder }) {
    const afterOpenModal = () => {};
    const {
        state: { homeFolder }
    } = React.useContext(AuthContext);

    const [selectedOption, setSelectedOption] = useState(null);
    const [getSubfolders] = useGetSubfolders();

    useEffect(() => {
        if (currentFolder) {
            setSelectedOption({
                id: currentFolder.id,
                title: currentFolder.title
            });
        }
    }, [currentFolder]);

    const [createFolder, { data, loading, error }] = useMutation(MUTATION.CREATE_FOLDER, {
        onCompleted: res => {
            if (subfolder){
                customAnalytics.create_subfolder_from_folder();
            } else {
                customAnalytics.create_home_folder();
            }
        }
    });

    // const setUpdatedTree = async response => {
    //     console.log('brisem kalsjdf', response.data.createFolder);
    //     if (response.data.createFolder) {
    //         let folder = response.data.createFolder;
    //         // TODO this should help in focusing on the proper folder
    //         let isSet = await getSubfolders(folder, true);
    //         console.log('let see whats here', isSet);
    //         if (isSet) {
    //             closeModal(response);
    //         }
    //     }
    //     setSelectedOption(null);
    // };

    const onCreatedFolder = response => {
        closeModal(response);
        setSelectedOption(null);
    };

    const checkCurrentOption = () => {
        if (currentFolder) {
            let option = {
                value: currentFolder.id,
                label: currentFolder.title
            };
            return option;
        }
        return null;
    };

    const onCreateFolder = async values => {
        let response;
        if ((selectedOption && selectedOption.id) === homeFolder.id) {
            response = await createFolder({
                variables: {
                    data: {
                        title: values.folderTitle,
                        parent: null
                    }
                }
            });
        } else {
            response = await createFolder({
                variables: {
                    data: {
                        title: values.folderTitle,
                        parent: selectedOption ? selectedOption.id : null
                    }
                }
            });
        }

        onCreatedFolder(response);
    };

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={addFolderModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 400,
                    height: 300
                }}
            >
                <div style={{ flexDirection: 'row', display: 'flex', marginBottom: 16 }}>
                    {selectedOption ? (
                        <div style={styles.title}>
                            Create folder in<span style={{ color: WHITE }}> {selectedOption.title}</span>
                        </div>
                    ) : (
                        <div style={styles.title}>Create folder</div>
                    )}
                    {/* <IoIosClose onClick={closeModal} style={{ height: 24, width: 24, cursor: 'pointer' }} /> */}
                </div>
                <Formik
                    initialValues={{ folderTitle: '' }}
                    onSubmit={async (values, actions) => {
                        // if (currentFolder) {
                        //     let response = await createFolder({
                        //         variables: {
                        //             data: {
                        //                 title: values.folderTitle,
                        //                 parent: currentFolder.id
                        //             }
                        //         }
                        //     });
                        //     onCreatedFolder(response);
                        // } else {
                        onCreateFolder(values);
                        // }
                        // closeModal();
                    }}
                    validationSchema={Yup.object().shape({
                        folderTitle: Yup.string().required('Required')
                    })}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors }) => (
                        <form
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flex: 1
                            }}
                        >
                            {/* <SelectFolderComponent
                                style={{ backgroundColor: SECONDARY_COLOR }}
                                currentOption={checkCurrentOption()}
                                onSelectedOption={value => {
                                    console.log('the option got selected', value);
                                    setSelectedOption(value);
                                }}
                            ></SelectFolderComponent> */}
                            <div></div>
                            <div style={{ marginTop: -16 }}>
                                {/* <div style={{ marginBottom: 8, color: PALE_WHITE_6 }}>Name</div> */}
                                <div style={styles.textInputContainer}>
                                    <Input
                                        name="folderTitle"
                                        type="text"
                                        autoFocus
                                        placeholder="Folder name"
                                        values={values.folderTitle}
                                        onChange={handleChange}
                                        onKeyDown={event => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                handleSubmit();
                                            }
                                        }}
                                    />
                                </div>
                                <ErrorMessage
                                    name="folderTitle"
                                    render={msg => {
                                        console.log('msg', msg);
                                        // return <Text style={styles.errorMsg}>{msg}</Text>;
                                        return (
                                            <div
                                                id="folderTitle"
                                                className="error"
                                                style={{ marginLeft: 0, marginTop: 4 }}
                                            >
                                                {msg}
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            <Button onClick={handleSubmit} type="submit">
                                Create
                            </Button>
                        </form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

const addFolderModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 'none',
        // borderColor: 'red',
        maxWidth: 600,
        backgroundColor: '#222729'
    }
};

const styles = {
    textInputContainer: {
        flexDirection: 'row',
        borderRadius: 12,
        display: 'flex'
        // alignItems: "center"
        // marginVertical: Platform.OS === 'ios' ? 4 : 0
    },
    title: {
        color: PALE_WHITE_6,
        fontWeight: 500,
        fontSize: 16,
        flex: 1
    }
};

const Input = styled.input`
    padding-left: 10px;
    flex: 1px;
    color: ${WHITE};
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${PALE_WHITE_4};
    background-color: ${SECONDARY_COLOR};

    :hover {
        border: 1px solid ${PALE_WHITE_6};
    }

    :focus {
        border: 1px solid ${PALE_WHITE_6};
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${PALE_WHITE_4};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${PALE_WHITE_4};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${PALE_WHITE_4};
    }
`;

const Button = styled.button`
    border-radius: 8px;
    border: none;
    background-color: ${PALE_WHITE_2};
    justify-content: center;
    display: flex;
    color: white;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    :focus {
        border: 1px solid ${PALE_WHITE_6};
    }
    &:hover {
        background-color: ${PALE_WHITE_3};
    }
`;

export default CreateFolderModal;
