import React from 'react';
import AudioMessage from './AudioMessage';

function AudioSection({ file }) {
    let title;
    if (file.displayName) {
        title = file.displayName;
    } else if (file.name) {
        title = file.name;
    } else {
        title = new Date().toISOString();
    }
    return (
        <div style={{ position: 'relative' }}>
            <AudioMessage 
                url={file.url} 
                title={title}
                fileName={file.name}
                fileSize={file.size}
                fileDisplayName={file.displayName}
            />
        </div>
    );
}

export default AudioSection;
