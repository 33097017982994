import React, { useState } from 'react';
import { prettySize } from '../../../util/fileSizeCalculator';

import FileIcon from '../../../appearance/FileIcon';
import ImageIcon from '../../../appearance/ImageIcon';

import { imageExists } from '../../../util/imageExists';

import styled from 'styled-components';

import { SECONDARY_COLOR, PRIMARY_COLOR, NOTE_BACKGROUND } from '../../../appearance/Colors';

import CarouselModal from '../../../components/Modals/CarouselModal';

import useDownloadFile from '../../../hooks/useDownloadFile';

function FileSection({ file }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const { url, name, displayName, size, type, format } = file;
    let maxTypeChar = 28;

    const downloadFile = useDownloadFile();

    const renderCarousel = item => {
        return (
            <CarouselModal
                isOpen={lightboxIsOpen}
                closeModal={e => {
                    e.stopPropagation();
                    setLightboxIsOpen(false);
                }}
                items={[item]}
                fileType="File"
            ></CarouselModal>
        );
    };

    const fileName = () => {
        let title;
        if (displayName) {
            title = displayName;
        } else if (name) {
            title = name;
        } else {
            // in case that we haven't saved the name of the file
            title = url.substr(url.lastIndexOf('/') + 1); // Add assignment to title
        }
        return title;
    };

    const renderFileType = (url, type, format) => {
        if (type.includes('image')) {
            if (!type.includes('image/heic') || (format && !format.includes('heic'))) {
                return (
                    <div
                        style={{
                            minHeight: 40,
                            borderRadius: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: NOTE_BACKGROUND
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            setLightboxIsOpen(true);
                        }}
                    >
                        <img
                            src={url}
                            style={{ width: 200, objectFit: 'cover', borderRadius: 4, cursor: 'zoom-in' }}
                        ></img>
                    </div>
                );
            } else {
                return (
                    <div style={{ marginRight: 4 }}>
                        <ImageIcon />
                    </div>
                );
            }
        } else {
            return (
                <div style={{ marginRight: 4 }}>
                    <FileIcon />
                </div>
            );
        }
    };

    return (
        <div
            style={{
                // backgroundColor: 'white',
                borderRadius: 4,
                flexDirection: 'row',
                display: 'flex'
                // marginTop: 4,
                // marginBottom: 4,
                // maxWidth: 300
            }}
        >
            <div style={{ marginRight: 8 }}>{renderFileType(url, type, format)}</div>

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ marginBottom: 4, wordBreak: 'break-word', color: PRIMARY_COLOR, fontWeight: 500 }}>
                    {fileName()}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: 8, color: SECONDARY_COLOR }}>{size ? prettySize(size) : null}</div>

                    <ALink href={file.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 8 }}>
                        Open
                    </ALink>
                    <ALink onClick={() => downloadFile(file.name, file.size, file.displayName)} rel="noopener noreferrer">
                        Download
                    </ALink>
                </div>
            </div>
            {renderCarousel(file)}
        </div>
    );
}

const ALink = styled.a`
    font-weight: 500 !important;
    color: #2b88d9 !important;
    &:hover {
        color: #0770cc !important;
    }
`;

export default FileSection;
