import React, { useState, useEffect } from 'react';

// drag and drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import styled from 'styled-components';

import FolderContainer from './Folder/FolderContainer';
import FolderListComponent from './FolderListComponent';
import FolderOptionsComponent from './FolderOptionsComponent';

import { useQuery, useLazyQuery, useMutation, useApolloClient } from '@apollo/react-hooks';

import { QUERY, MUTATION } from '../gql-operations';

import Hotkeys from 'react-hot-keys';
import SearchFoldersModal, { searchActions } from '../components/Modals/SearchFoldersModal';
import LimitationModal from '../components/Modals/LimitationModal';
import SearchMessagesModal from '../components/Modals/SearchMessagesModal';

import LoadingProgress from './Notifications/LoadingProgress';

import { STARRED, CHECKBOXES, HIERARCHY, SEARCH_MESSAGES, HISTORY } from '../util/specialFolderEnums';
import StarredFolderContainer from './StarredFolderContainer';
import CheckboxesFolderContainer from './CheckboxesFolderContainer';
import SearchMessagesContainer from '../components/Folder/FolderCotainer/SearchMessagesContainer';
import HierarchyFolderContainer from '../components/Folder/FolderCotainer/HierarchyFolderContainer';
import HistoryFolderContainer from '../components/Folder/FolderCotainer/HistoryFolderContainer';
import MainBackgroundSection from './MainBackgroundSection';

import { setupInitialTree } from '../util/serializeTreeArray';

import { AuthContext } from '../util/context';

import { PRIMARY_COLOR, SECONDARY_COLOR } from '../appearance/Colors';

import useRedirectErrors from '../errors/useRedirectErrors';

import { useScrollToBottom } from '../hooks/useScrollToBottom';
import useWindowDimensions from '../hooks/useWindowDimensions';

// import { ChatWidget, ChatWindow, Papercups } from '@papercups-io/chat-widget';
import { Crisp } from "crisp-sdk-web";

// Crisp will be displayed
// import { Storytime } from '@papercups-io/storytime';
import customAnalytics from '../util/custom-analytics';
import { useHistory } from 'react-router-dom';
import SettingsModal from './Modals/SettingsModal';
import { prettySize } from '../util/fileSizeCalculator';

function DnDComponent({ }) {
    const client = useApolloClient();
    let history = useHistory();
    const [showFoldersSearch, setShowFoldersSearch] = useState(false);
    const [showClientError, setShowClientError] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const { passingErrors } = useRedirectErrors();
    const [scrollToBottom] = useScrollToBottom();
    const { height, width } = useWindowDimensions();
    // const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const [getStarredMessagesGlobal, { data: starredGlobal, error: starredError, loading: starredLoading }] =
        useLazyQuery(QUERY.GET_STARRED_MESSAGES_GLOBAL, {
            onError: res => {
                passingErrors(res);
            }
        });

    const [getCheckedMessagesGlobal, { data: checkedGlobal, error: checkedError, loading: checkedLoading }] =
        useLazyQuery(QUERY.GET_CHECKBOX_MESSAGES_GLOBAL, {
            onError: res => {
                passingErrors(res);
            }
        });

    const { data: userData } = useQuery(QUERY.ME, {
        onCompleted: () => {
            // setCrisp()
        }
    });

    const [getFolderContent] = useLazyQuery(QUERY.GET_FOLDER_CONTENT, {
        onCompleted: async res => {
            console.log('this is home', res);
            let folder = res.getFolderContent.folder;
            await localStorage.setItem('homeFolder', JSON.stringify(folder));
            dispatch({
                type: 'SET_HOME_FOLDER',
                homeFolder: folder
            });
            dispatch({
                type: 'SET_SELECTED_FOLDER',
                selectedFolder: folder
            });
        }
    });

    const [updateUserDetails] = useMutation(MUTATION.UPDATE_USER_DETAILS, {
        onCompleted: res => {
            if (res) {
                customAnalytics.user_edit();
            }
        }
    });

    const updateUserMutation = () => {
        updateUserDetails({
            variables: {
                data: {
                    emergencyMessages: true
                }
            }
        });
        // closeModal();
    };

    // HOME FOLDERS QUERY
    const [getHomeFolders, { data: folders, loading: foldersLoading, error: foldersError, subscribeToMore }] =
        useLazyQuery(QUERY.GET_HOME_FOLDERS, {
            onError: res => {
                passingErrors(res);
            },
            onCompleted: async res => {
                console.log('i set you up only once', res);

                let createdTree = setupInitialTree(res.getHomeFolders.folders);
                dispatch({
                    type: 'SET_TREE',
                    treeData: createdTree
                });

                // dispatch({
                //     type: 'SET_SELECTED_FOLDER',
                //     selectedFolder: folders.getHomeFolders.folders[0]
                // });
                getStarredMessagesGlobal();
                getCheckedMessagesGlobal();

                let currentHomeFolder = JSON.parse(await localStorage.getItem('homeFolder'));

                if (!currentHomeFolder) {
                    dispatch({
                        type: 'SET_SELECTED_FOLDER',
                        selectedFolder: folders.getHomeFolders.folders[0]
                    });
                }
            }
            // fetchPolicy: 'cache-and-network'
        });

    useEffect(() => {
        getHomeFolders();
        // const st = Storytime.init({
        //     accountId: 'b94d1676-dbbb-4d9f-a516-e3bb70fbdae2',

        //     // Optionally pass in metadata to identify the customer
        //     // customer: {
        //     //  name: 'Test User',
        //     //  email: 'test@test.com',
        //     //  external_id: '123',
        //     // },

        //     // Optionally specify the base URL
        //     baseUrl: 'https://app.papercups.io'
        // });
        Crisp.chat.onChatClosed(() => {
            Crisp.chat.close();
            Crisp.chat.hide();
            setPapercups(false);
        })
    }, []);

    const setCrisp = () => {
        Crisp.configure('0dccd3b5-86ed-4a02-88f3-859e0058d0c1');
        Crisp.user.setAvatar(`https://res.cloudinary.com/luckyimagecloud/image/upload/v1710585102/` + userData.me.profilePictureUrl)
        Crisp.user.setEmail(userData.me.email)
        Crisp.user.setNickname(userData.me.fullName)
        Crisp.session.setData({
            user_id: userData.me.id,
            plan: userData.me.planType,
            verified: userData.me.isVerified,
            messages: userData.me.quota.totalMessages,
            msgBuffer: userData.me.emergencyBuffer ? userData.me.emergencyBuffer : 0,
            storage: prettySize(userData.me.quota.totalStorage),
            referralCount: userData.me.referralCount
        });
        Crisp.setTokenId(userData.me.id);
        Crisp.chat.hide()
        // let data = Crisp.session.getIdentifier()

        // Doesn't work
        // if (!state.showPapercups) {
        //     console.log('PAPER ON')
        //     Crisp.chat.show()
        // } else {
        //     Crisp.chat.hide()
        //     console.log('PAPER OFF')
        // }

        // Function to open the Crisp chatbox
        Crisp.message.onMessageComposeReceived(() => {
            // working
        })
        Crisp.message.onMessageSent(() => {
            // working
        })
        Crisp.message.onMessageReceived((cot) => {
            // console.log('first', cot)
            Crisp.chat.show();
        })

        setTimeout(() => {
            // console.log('Crisp.chat.unreadCount()', Crisp.chat.unreadCount())
            if ((Crisp && Crisp.chat) && (Crisp.chat.unreadCount().valueOf() > 0)) {
                // console.log('show me')
                Crisp.chat.show();
            }
        }, 3000);

    }


    const checkPapercups = async () => {
        let papercups = await localStorage.getItem('PAPERCUPS');
        if (papercups !== null) {
            setPapercups(!papercups);
        }
    };

    const setPapercups = async bool => {
        await dispatch({
            type: 'SET_SHOW_PAPERCLIP',
            showPapercups: bool
        });
        if (bool) {
            // Papercups.open();
            Crisp.chat.show();
            Crisp.chat.open();
            customAnalytics.help_center_open();
        }
        await localStorage.setItem('PAPERCUPS', bool);
    };

    const checkHomeFolder = async () => {
        let currentHomeFolder = JSON.parse(await localStorage.getItem('homeFolder'));
        console.log('currenthome is', currentHomeFolder);
        if (currentHomeFolder) {
            dispatch({
                type: 'SET_HOME_FOLDER',
                homeFolder: currentHomeFolder
            });

            if ((userData && userData.me.homeFolder) !== currentHomeFolder.id) {
                getFolderContent({
                    variables: {
                        data: {
                            folderId: userData.me.homeFolder
                        }
                    }
                });
            } else {
                if (!state.selectedFolder) {
                    dispatch({
                        type: 'SET_SELECTED_FOLDER',
                        selectedFolder: currentHomeFolder
                    });
                }
            }
        } else {
            if (userData && userData.me.homeFolder) {
                getFolderContent({
                    variables: {
                        data: {
                            folderId: userData.me.homeFolder
                        }
                    }
                });
            }
        }
    };

    const signOut = async () => {
        localStorage.clear();
        // await localStorage.removeItem('token');
        // await localStorage.removeItem('refreshToken');
        // await localStorage.removeItem('userData');
        client.resetStore();
        history.push('/login');
    };

    const checkIfDeleted = () => {
        console.log('userData', userData)
        if (userData.me.deletedAt) {
            signOut();
        }
    }

    const addAnalytics = async () => {
        // console.log('setting analy', userData);
        let path = window.location.pathname + window.location.search;
        customAnalytics.addAnalytics(path, userData.me)
    };

    useEffect(() => {
        if (userData) {
            checkIfDeleted();
            checkHomeFolder();
            checkPapercups();
            setCrisp();
            addAnalytics();
        }
    }, [userData]);

    // not used
    const [state, dispatch] = React.useReducer(
        (prevState, action) => {
            switch (action.type) {
                case 'SET_TREE':
                    return {
                        ...prevState,
                        treeData: action.treeData
                    };
                case 'SET_FOLDER_FILTER':
                    return {
                        ...prevState,
                        folderFilterType: action.folderFilterType
                    };
                case 'SET_SEARCH_FOLDER_FILTER':
                    return {
                        ...prevState,
                        searchFolderFilterType: action.searchFolderFilterType
                    };
                case 'SET_SELECTED_FOLDER':
                    return {
                        ...prevState,
                        selectedFolder: action.selectedFolder
                    };
                case 'SET_SELECTED_MESSAGE':
                    return {
                        ...prevState,
                        selectedMessage: action.selectedMessage
                    };
                case 'SET_UPLOAD_PROGRESS':
                    return {
                        ...prevState,
                        uploadProgress: action.uploadProgress
                    };
                case 'SET_HOLDING_KEYS':
                    return {
                        ...prevState,
                        holdingKeys: action.holdingKeys,
                        keys: action.keys
                    };
                case 'SET_SHOW_DELETED':
                    return {
                        ...prevState,
                        showDeletedMessages: action.showDeletedMessages
                    };
                case 'SET_SHOW_SEARCH_MODAL':
                    return {
                        ...prevState,
                        showSearchModal: action.showSearchModal
                    };
                case 'SET_HOME_FOLDER':
                    return {
                        ...prevState,
                        homeFolder: action.homeFolder
                    };
                case 'SET_MESSAGES_TO_SEND':
                    return {
                        ...prevState,
                        messagesToSend: action.messagesToSend
                    };
                case 'SET_MESSAGES_MAP':
                    return {
                        ...prevState,
                        selectedMessagesMap: action.selectedMessagesMap
                    };
                case 'SET_SHOW_MULTISELECT':
                    return {
                        ...prevState,
                        showMultiSelect: action.showMultiSelect
                    };
                case 'SET_SHOW_BACKGROUNDSELECT':
                    return {
                        ...prevState,
                        showBackgroundSelect: action.showBackgroundSelect
                    };
                case 'SET_GET_SUBFOLDERS':
                    return {
                        ...prevState,
                        getSubfolders: action.getSubfolders
                    };
                case 'SET_SEARCH_TERM':
                    return {
                        ...prevState,
                        searchTerm: action.searchTerm
                    };
                case 'SET_SEARCH_GLOBAL':
                    return {
                        ...prevState,
                        searchGlobal: action.searchGlobal
                    };
                case 'SET_SEARCH_DELETED':
                    return {
                        ...prevState,
                        searchDeletedMessages: action.searchDeletedMessages
                    };
                case 'SET_SEARCH_ACTIVE':
                    return {
                        ...prevState,
                        searchActive: action.searchActive
                    };
                case 'SET_CLIENT_ERROR':
                    return {
                        ...prevState,
                        clientError: action.clientError
                    };
                case 'SET_SHOW_PAPERCLIP':
                    return {
                        ...prevState,
                        showPapercups: action.showPapercups
                    };
            }
        },
        {
            treeData: null,
            folderFilterType: [],
            searchFolderFilterType: [],
            selectedFolder: null,
            selectedMessage: null,
            uploadProgress: undefined,
            holdingKeys: false,
            keys: {},
            showDeletedMessages: false,
            homeFolder: undefined,
            messagesToSend: [],
            selectedMessagesMap: {},
            showMultiSelect: false,
            showBackgroundSelect: false,
            getSubfolders: {},
            clientError: undefined,
            showPapercups: true,
            searchTerm: '',
            searchGlobal: true,
            searchDeletedMessages: false,
            searchActive: false,
            showSearchModal: false
        }
    );

    const authContext = React.useMemo(
        () => ({
            setNewTree: async data => {
                if (data) {
                    console.log('setting the tree data', data);
                    dispatch({ type: 'SET_TREE', treeData: data });
                }
            },
            updateFoldersOnTree: async foldersToUpdate => {
                if (foldersToUpdate) {
                    let cloneTree = state.treeData;
                    await foldersToUpdate.forEach(incomingFolder => {
                        if (incomingFolder && cloneTree.items[incomingFolder.id]) {
                            cloneTree.items[incomingFolder.id].data = {
                                ...incomingFolder
                            };
                        }
                    });
                    dispatch({ type: 'SET_TREE', treeData: cloneTree });
                }
            },
            setFolderFilter: async data => {
                console.log('getting the filter data', data);
                customAnalytics.filter_actived(data);
                dispatch({
                    type: 'SET_FOLDER_FILTER',
                    folderFilterType: [...data]
                });
            },
            setSearchFolderFilter: async data => {
                console.log('getting the search filter data', data);
                customAnalytics.search_filter_actived(data);
                dispatch({
                    type: 'SET_SEARCH_FOLDER_FILTER',
                    searchFolderFilterType: [...data]
                });
            },
            setSelectedFolder: async data => {
                console.log('selectingfolder', data);
                dispatch({
                    type: 'SET_SELECTED_FOLDER',
                    selectedFolder: data
                });
            },
            setSelectedMessage: async data => {
                console.log('selected messages', data);
                // it can also be undefined so we hide the component
                dispatch({
                    type: 'SET_SELECTED_MESSAGE',
                    selectedMessage: data
                });
            },
            setUploadProgress: async data => {
                // console.log('hehe', data);
                dispatch({
                    type: 'SET_UPLOAD_PROGRESS',
                    uploadProgress: data
                });
            },
            setHoldingKeys: data => {
                // console.log('holding it', data);
                dispatch({
                    type: 'SET_HOLDING_KEYS',
                    holdingKeys: data.holdingKeys,
                    keys: data.keys
                });
            },
            setShowDeletedMessages: async data => {
                // console.log('hehee', data)
                if (data) {
                    customAnalytics.show_deleted_messages();
                }
                dispatch({
                    type: 'SET_SHOW_DELETED',
                    showDeletedMessages: data
                });
            },
            setShowSearchModal: async data => {
                dispatch({
                    type: 'SET_SHOW_SEARCH_MODAL',
                    showSearchModal: data
                });
            },
            setHomeFolder: async data => {
                // console.log('hehee', data);
                dispatch({
                    type: 'SET_HOME_FOLDER',
                    homeFolder: data
                });
            },
            setMessagesToSend: async data => {
                // console.log('hehee1', data)
                dispatch({
                    type: 'SET_MESSAGES_TO_SEND',
                    messagesToSend: data ? data : []
                });
            },
            setSelectedMessagesMap: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_MESSAGES_MAP',
                    selectedMessagesMap: data
                });
            },
            setShowMultiSelect: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_SHOW_MULTISELECT',
                    showMultiSelect: data
                });
            },
            setShowBackgroundSelect: async data => {
                // console.log('hehee', data)
                customAnalytics.modalview('Change Background');
                dispatch({
                    type: 'SET_SHOW_BACKGROUNDSELECT',
                    showBackgroundSelect: data
                });
            },
            setGetSubfolders: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_GET_SUBFOLDERS',
                    getSubfolders: data
                });
            },
            setSearchTerm: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_SEARCH_TERM',
                    searchTerm: data
                });
            },
            setSearchGlobal: async data => {
                // console.log('global is', data)
                dispatch({
                    type: 'SET_SEARCH_GLOBAL',
                    searchGlobal: data
                });
            },
            setSearchDeletedMessages: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_SEARCH_DELETED',
                    searchDeletedMessages: data
                });
            },
            setSearchActive: async data => {
                // console.log('hehee', data)
                dispatch({
                    type: 'SET_SEARCH_ACTIVE',
                    searchActive: data
                });
            },
            setClientError: async data => {
                setShowClientError(true);
                dispatch({
                    type: 'SET_CLIENT_ERROR',
                    clientError: data
                });
            },
            setShowPaperclip: async data => {
                setPapercups(data);
            }
        }),
        [state.treeData]
    );

    useEffect(() => {
        document.addEventListener('visibilitychange', () => {
            // document.title = document.hidden ? "I'm away" : "I'm here";
            // if (document.hidden) {
            // console.log('hos many times');
            dispatch({
                type: 'SET_HOLDING_KEYS',
                holdingKeys: false,
                keys: {}
            });
            // }
        });
    }, []);

    useEffect(() => {
        // setting up global subscriptino for folders
        // if (folders) {
        // kad dobije novi afterware, trebalo bi da uradi resubscribe

        // NOT USED ANY LONGER
        // console.log('creating subscription every time when folders change');
        // let unsubscribe = subscribeToFolders({
        // 	document: NEW_FOLDER_SUBSCRIPTION,
        // 	variables: {},
        // 	updateQuery: (previousResult2, { subscriptionData }) => {
        // 		console.log('subsription previous res', previousResult2);
        // 		console.log('subsription New Data', subscriptionData);
        // 		if (!subscriptionData.data) return previousResult2;
        // 		const newFolder = subscriptionData.data.newFolder;
        // 		// setting the folder to active
        // 		// newFolder.active = true;
        // 		// setSelectedFolder(newFolder);
        // 		// console.log(newFolder);
        // 		return {
        // 			getHomeFolders: {
        // 				// we are not cursoring the folders, that's why it's only previousResult2
        // 				folders: [...previousResult2.getHomeFolders.folders, newFolder],
        // 				cursor: previousResult2.getHomeFolders.cursor,
        // 				__typename: previousResult2.getHomeFolders.__typename,
        // 			},
        // 		};
        // 	},
        // });
        // }
        return () => {
            // unsubscribe();
        };
    }, [folders]);

    if (foldersLoading) {
        return null;
    }

    const renderSearchContainer = () => {
        if (state.searchActive) {
            return (
                <div
                    style={{
                        display: 'flex',
                        left: 300,
                        right: state.selectedMessage ? 330 : 0,
                        zIndex: 1,
                        position: 'absolute',
                        height: '100%'
                    }}
                >
                    <SearchMessagesContainer />
                </div>
            );
        }
    };

    const renderFolderContainer = () => {
        if (state.selectedFolder) {
            if (state.selectedFolder.id === STARRED) {
                return <StarredFolderContainer initialData={starredGlobal} />;
            } else if (state.selectedFolder.id === CHECKBOXES) {
                return (
                    <CheckboxesFolderContainer
                    // he automaticall puts it in cache
                    // initialData={checkedGlobal}
                    />
                );
            } else if (state.selectedFolder.id === HIERARCHY) {
                return <HierarchyFolderContainer />;
            } else if (state.selectedFolder.id === HISTORY) {
                return <HistoryFolderContainer />;
            } else if (state.selectedFolder) {
                return <FolderContainer />;
            }
        }

        return <div style={{ flex: 1 }}></div>;
    };

    let contextObject = {
        state: state,
        context: authContext
    };

    if (state.treeData) {
        return (
            <AuthContext.Provider value={contextObject}>
                <LoadingProgress />
                <Hotkeys
                    keyName="command+K, ctrl+K, command+1, ctrl+1, command+2, ctrl+2,command+3, ctrl+3,command+4, ctrl+4, command+N, command+P, /, command+down, ctrl+down, command+P, ctrl+P "
                    onKeyDown={(keyName, e, handle) => {
                        // console.log('working', e);
                        // console.log('working key', keyName);
                        // console.log('working handle', handle);

                        var activeElement = document.activeElement;
                        var inputs = ['input', 'select', 'button', 'textarea'];

                        if (keyName === '/') {
                            if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
                                // checking if the focus is already on some input field
                                return false;
                            } else if (activeElement.hasAttribute('contentEditable')) {
                                return false;
                            } else {
                                // otherwise put the focus on messageInput
                                if (document.getElementById('messageInput')) {
                                    e.preventDefault();
                                    document.getElementById('messageInput').focus();
                                }
                            }
                            customAnalytics.ui_action({
                                "action": "Input Focus",
                                "shortcut_key": keyName
                            })
                        }
                        // setShowFoldersSearch(true);
                        if (keyName === 'command+K' || keyName === 'ctrl+K') {
                            // closeMessage();
                            e.preventDefault();
                            setShowFoldersSearch(true);
                            customAnalytics.ui_action({
                                "action": "Search Folders",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+P' || keyName === 'ctrl+P') {
                            // closeMessage();
                            e.preventDefault();
                            dispatch({
                                type: 'SET_SHOW_SEARCH_MODAL',
                                showSearchModal: true
                            });
                            customAnalytics.ui_action({
                                "action": "Search Messages",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+1' || keyName === 'ctrl+1') {
                            // closeMessage();
                            e.preventDefault();
                            console.log('this is the one', state.homeFolder);
                            // if (state.homeFolder.id.toString() !== state.selectedFolder.id.toString()) {
                            if (state.homeFolder.id.toString() !== state.selectedFolder.id.toString()) {
                                dispatch({
                                    type: 'SET_SELECTED_FOLDER',
                                    selectedFolder: state.homeFolder
                                });
                                if (document.getElementById(state.homeFolder.id)) {
                                    document.getElementById(state.homeFolder.id).scrollIntoView();
                                }
                            } else {
                                scrollToBottom();
                            }
                            customAnalytics.ui_action({
                                "action": "Jump to Inbox",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+2' || keyName === 'ctrl+2') {
                            // closeMessage();
                            e.preventDefault();
                            if (state.homeFolder) {
                                dispatch({
                                    type: 'SET_SELECTED_FOLDER',
                                    selectedFolder: { id: HIERARCHY, title: 'All' }
                                });
                                if (document.getElementById(state.homeFolder.id)) {
                                    document.getElementById(state.homeFolder.id).scrollIntoView();
                                }
                            }
                            customAnalytics.ui_action({
                                "action": "Jump to All",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+3' || keyName === 'ctrl+3') {
                            // closeMessage();
                            e.preventDefault();
                            if (state.homeFolder) {
                                dispatch({
                                    type: 'SET_SELECTED_FOLDER',
                                    selectedFolder: { id: STARRED, title: 'Stars' }
                                });
                                if (document.getElementById(state.homeFolder.id)) {
                                    document.getElementById(state.homeFolder.id).scrollIntoView();
                                }
                            }
                            customAnalytics.ui_action({
                                "action": "Jump to Stars",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+4' || keyName === 'ctrl+4') {
                            // closeMessage();
                            e.preventDefault();
                            if (state.homeFolder) {
                                dispatch({
                                    type: 'SET_SELECTED_FOLDER',
                                    selectedFolder: { id: CHECKBOXES, title: 'Tasks' }
                                });
                                if (document.getElementById(state.homeFolder.id)) {
                                    document.getElementById(state.homeFolder.id).scrollIntoView();
                                }
                            }
                            customAnalytics.ui_action({
                                "action": "Jump to Tasks",
                                "shortcut_key": keyName
                            })
                        }
                        if (keyName === 'command+down' || keyName === 'ctrl+down') {
                            scrollToBottom();
                            customAnalytics.ui_action({
                                "action": "Scroll to Bottom",
                                "shortcut_key": keyName
                            })
                        }
                        // if (keyName === 'command+P') {
                        //     e.preventDefault();
                        // }
                    }}
                    filter={event => {
                        return true;
                    }}
                >
                    <DndProvider backend={HTML5Backend}>
                        <MainBackgroundSection>
                            <MainWrapper>
                                {/* FOLDERS LIST CONTAINER*/}
                                <FolderListComponent subscribeToMore={subscribeToMore} />

                                {/* FOLDER MESSAGES CONTAINER*/}
                                {renderSearchContainer()}

                                {renderFolderContainer()}

                                <FolderOptionsComponent />
                            </MainWrapper>
                        </MainBackgroundSection>
                    </DndProvider>
                    <SearchFoldersModal
                        isOpen={showFoldersSearch}
                        title="Jump to folder"
                        action={searchActions.JUMP}
                        disableMoveToInbox
                        closeModal={folder => {
                            setShowFoldersSearch(false);
                            if (folder) {
                                dispatch({
                                    type: 'SET_SELECTED_FOLDER',
                                    selectedFolder: folder
                                });
                                if (document.getElementById(folder.id)) {
                                    document.getElementById(folder.id).scrollIntoView();
                                }
                            }
                        }}
                    ></SearchFoldersModal>
                    {/* {userData && state ? (
                        <ChatWidget
                            styles={{
                                toggleContainer: {
                                    bottom: 70,
                                    right: 10
                                },
                                chatContainer: {
                                    bottom: 130,
                                    right: 10,
                                    maxHeight: '70%'
                                }
                            }}
                            // newMessagesNotificationText="View me"
                            // onMessageReceived={() => {
                            //     setPapercups(true);
                            // }}
                            onChatClosed={() => {
                                setPapercups(false);
                            }}
                            hideToggleButton={!state.showPapercups}
                            accountId="b94d1676-dbbb-4d9f-a516-e3bb70fbdae2"
                            title="Welcome to Luckynote"
                            subtitle="Ask us anything in the chat window below 😊"
                            primaryColor={SECONDARY_COLOR}
                            // Optionally pass in a default greeting
                            greeting={`Hi ${userData.me.fullName}! How can I help you?`}
                            awayMessage={`Hi ${userData.me.fullName}! We're currently offline and come back to you as soon as possible, thanks!`}
                            newMessagePlaceholder="Start typing..."
                            // popUpInitialMessage={true}
                            // setDefaultGreeting={res => {
                            //     console.log('res', res)
                            // }}
                            // Add this if you want to indicate when you/your agents
                            // are online or offline to your customers
                            showAgentAvailability={true}
                            agentAvailableText="Online right now!"
                            agentUnavailableText="Away at the moment."
                            // Add this if you want to require the customer to enter
                            // their email before being able to send you a message
                            requireEmailUpfront={false}
                            iconVariant="filled"
                            // Optionally pass in metadata to identify the customer
                            customer={{
                                name: userData.me.fullName,
                                email: userData.me.email,
                                external_id: userData.me.id,
                                metadata: { version: 1, plan: userData.me.planType } // Custom fields go here
                            }}
                            // Optionally specify the base URL
                            baseUrl="https://app.papercups.io"
                        />
                    ) : null} */}
                    <LimitationModal
                        closeTitle="Continue without"
                        // actionTitle="Add 100 messages"
                        actionTitle2="Upgrade"
                        title="You've reached your limit"
                        description={state.clientError && state.clientError[0].message}
                        userData={userData}
                        closeModal={result => {
                            setShowClientError(false);
                            dispatch({
                                type: 'SET_CLIENT_ERROR',
                                clientError: null
                            });
                            if (result) {
                                // window.open(
                                //     `mailto:support@luckynote.io?subject=Free tier limit reached&body=Hi Luckynote crew,%0a%0aI have reached the Free tier limit. What now?%0a%0aCheers!`
                                // );
                                if (result === "PRO") {
                                    setShowSettingsModal(true);
                                    // window.open(
                                    //     `https://buy.stripe.com/28obMG34RarF6Pu3cf`
                                    // );
                                }
                                if (result === "EMERGENCY") {
                                    updateUserMutation();
                                }
                                // else {
                                //     // 300 messages
                                //     window.open(
                                //         `https://buy.stripe.com/5kAg2WbBn7ft5Lq145`
                                //     );
                                // }
                            }
                        }}
                        isOpen={showClientError}
                    ></LimitationModal>
                    <SearchMessagesModal
                        isOpen={state.showSearchModal}
                        closeModal={message => {
                            dispatch({
                                type: 'SET_SHOW_SEARCH_MODAL',
                                showSearchModal: false
                            });
                            if (message.id) {
                                dispatch({
                                    type: 'SET_SELECTED_MESSAGE',
                                    selectedMessage: message
                                });
                            }
                        }}
                    ></SearchMessagesModal>
                    <SettingsModal
                        // user={data}
                        isOpen={showSettingsModal}
                        tab={1}
                        closeModal={() => {
                            setShowSettingsModal(false);
                        }}
                    ></SettingsModal>
                </Hotkeys>
            </AuthContext.Provider>
        );
    } else {
        return null;
    }
}

const MainWrapper = styled.div`
    flex-direction: row;
    display: flex;
    height: 100%;
    // max-width: 1010px;
    min-width: 300px;
    margin: auto;
`;

export default DnDComponent;
