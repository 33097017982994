import React, { useState, useRef, useReducer } from 'react';
import FolderTitle from './FolderTitle';
import FolderOptionsMenu from '../../Menus/FolderOptionsMenu';
import SearchInputButton from '../../SearchInputButton';
import styled from 'styled-components';
import FilterComponent from '../Components/FilterComponent';

import { AuthContext } from '../../../util/context';
import { FiGrid } from 'react-icons/fi';
import { BsGrid, BsGridFill } from 'react-icons/bs';
import { IoIosSearch } from 'react-icons/io';
import { GREEN, PALE_WHITE_6, PRIMARY_COLOR_6, WHITE } from '../../../appearance/Colors';
import useCheckBrowser from '../../../util/useCheckBrowser';

import Loader from 'react-loader-spinner';
import customAnalytics from '../../../util/custom-analytics';

function FolderHeader({
    title,
    titleEditable,
    frontChildren,
    backChildren,
    titleSize,
    hideFilters,
    hideSearch,
    hideMenu,
    loading,
    alwaysOpen
}) {
    const {
        state: { selectedFolder, folderFilterType, selectedMessage, homeFolder },
        context: { setSelectedFolder, setSelectedMessage, setShowSearchModal }
    } = React.useContext(AuthContext);

    const { Platform, PLATFORMS } = useCheckBrowser();

    const titleRef = useRef();

    const [showFilterList, setShowFilterList] = useState(folderFilterType.length > 0);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const onSearchClick = () => {
        setShowSearchModal(true);
    };

    React.useEffect(() => {
        // just so the titleRef can rerender
        forceUpdate();
    }, [selectedMessage]);

    const renderFilterList = () => {
        if (false) {
            // if (titleRef.current && titleRef.current.getBoundingClientRect().width > 1500) {
            if (showFilterList) {
                setShowFilterList(false);
                customAnalytics.ui_action({
                    "action": 'Hide Filters'
                });
            }
            return (
                <>
                    <FilterComponent></FilterComponent>
                    <div style={{ flex: 1 }} />
                </>
            );
        } else {
            return (
                <>
                    <div style={{ flex: 1 }} />

                    <div>
                        <DropDownElement
                            style={{
                                cursor: 'pointer',
                                backgroundColor: !showFilterList && folderFilterType.length > 0 ? GREEN : PRIMARY_COLOR_6
                            }}
                            onClick={() => {
                                setShowFilterList(!showFilterList);
                                if (!showFilterList) {
                                    customAnalytics.ui_action({
                                        "action": 'Show Filters',
                                    });
                                }
                            }}
                        >
                            {showFilterList ? (
                                <BsGridFill color="white" size={20}></BsGridFill>
                            ) : (
                                <div style={{ position: 'relative' }}>
                                    {folderFilterType.length > 0 ? (
                                        <div
                                            style={{
                                                border: `1px solid ${WHITE}`,
                                                borderRadius: 10,
                                                height: 14,
                                                width: 14,
                                                backgroundColor: GREEN,
                                                position: 'absolute',
                                                top: -14,
                                                right: -15
                                            }}
                                        ></div>
                                    ) : null}

                                    <BsGrid color={WHITE} size={20}></BsGrid>
                                </div>
                            )}
                        </DropDownElement>
                    </div>
                </>
            );
        }
    };

    const renderSearchInput = () => {
        if (titleRef.current && titleRef.current.offsetWidth > 500) {
            return (
                <SearchInputButton
                    placeholder={`Search`}
                    tip={`${Platform === PLATFORMS.MACINTOSH ? '⌘P' : 'Ctr+P'}`}
                    onClick={() => {
                        onSearchClick();
                    }}
                ></SearchInputButton>
            );
        } else {
            return (
                <div>
                    <div style={{ position: 'relative' }}>
                        <DropDownElement
                            style={{ cursor: 'pointer', marginLeft: 8, marginRight: 8 }}
                            onClick={() => {
                                onSearchClick();
                            }}
                        >
                            <IoIosSearch size={20} color={WHITE}></IoIosSearch>
                        </DropDownElement>
                    </div>
                </div>
            );
        }
    };

    const editableTitle = () => {
        if (selectedFolder.deletedAt) {
            return false;
        } else if (selectedFolder.id === (homeFolder && homeFolder.id)) {
            return false;
        } else if (titleEditable) {
            return titleEditable;
        }
    };

    if (selectedFolder) {
        return (
            <>
                <FolderTitleWrapper ref={titleRef}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                            <FolderTitle
                                title={title}
                                folder={selectedFolder}
                                editable={editableTitle()}
                                titleSize={titleSize}
                            ></FolderTitle>
                            {loading ? (
                                <Loader
                                    type="Puff"
                                    // color="#00BFFF"
                                    color={PALE_WHITE_6}
                                    height={30}
                                    width={30}
                                // timeout={3000} //3 secs
                                />
                            ) : null}
                            {/* {contentEditableTitle()} */}
                            {frontChildren}

                            {!hideFilters ? renderFilterList() : <div style={{ flex: 1 }}></div>}

                            {backChildren}

                            {!hideSearch ? renderSearchInput() : null}

                            {!hideMenu ? <FolderOptionsMenu folder={selectedFolder}></FolderOptionsMenu> : null}
                        </div>
                        {(showFilterList && !hideFilters) || alwaysOpen ? (
                            <div style={{ marginBottom: 8 }}>
                                <FilterComponent></FilterComponent>
                            </div>
                        ) : null}
                    </div>
                </FolderTitleWrapper>
            </>
        );
    }
    return null;
}

const FolderTitleWrapper = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: row;
    display: flex;
    align-items: center;
    // min-height: 56px;
`;

const DropDownElement = styled.div`
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    height: 40px;
    width: 44px;
    margin: 8px 0px;
    border-radius: 12px;
    background-color: ${PRIMARY_COLOR_6};
    backdrop-filter: saturate(180%) blur(20px);
    &:hover {
        background: rgba(0, 0, 0, 0.5);
    }
`;

export default FolderHeader;
