import React, { useState, useEffect, useRef } from 'react';
import { QUERY } from '../../../gql-operations';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import styled, { css } from 'styled-components';

import { SHOW_OPTIONS } from '../../Messages/DefaultMessage';
import SearchMessagesModal from '../../../components/Modals/SearchMessagesModal';
import SearchFoldersModal from '../../../components/Modals/SearchFoldersModal';

import MessagesList from '../Components/MessagesList';
import MultiSelectOptions from '../Components/MultiSelectOptions';
import FilterComponent from '../Components/FilterComponent';
import ScrollToBottomButton from '../Components/ScrollToBottomButton';
import FolderHeader from '../Components/FolderHeader';

// import useMoveMessagesMutation from '../../../hooks/useMoveMessagesMutation';

import useKeyboardListener from '../../../hooks/useKeyboardListener';

import { AuthContext } from '../../../util/context';
import { pluralHelper } from '../../../util/pluralHelper';
import HideDeletedMessagesComponent from '../HideDeletedMessagesComponent';

import useRedirectErrors from '../../../errors/useRedirectErrors';
import {
    PRIMARY_COLOR,
    PALE_WHITE_6,
    PRIMARY_COLOR_6,
    WHITE,
    PALE_WHITE_1,
    PALE_WHITE_2,
    PALE_WHITE,
    GREEN,
    RED,
    PALE_WHITE_4,
    STARRED
} from '../../../appearance/Colors';

import Loader from 'react-loader-spinner';

import { IoIosClose, IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { CHECKBOXES, HIERARCHY } from '../../../util/specialFolderEnums';
import customAnalytics from '../../../util/custom-analytics';

const SHOW_TYPE = {
    DELETED: 'DELETED',
    GLOBAL: 'GLOBAL'
};

function SearchMessagesContainer({ enableShiftSelect = true }) {
    const {
        state: {
            selectedFolder: folder,
            searchGlobal,
            messagesToSend,
            selectedMessagesMap,
            searchTerm,
            searchDeletedMessages,
            searchFolderFilterType,
            folderFilterType,
            showMultiSelect
        },
        context: {
            setSelectedFolder,
            setMessagesToSend,
            setSelectedMessagesMap,
            setSearchTerm,
            setSearchGlobal,
            setSearchDeletedMessages,
            setSearchActive,
            setShowMultiSelect
        }
    } = React.useContext(AuthContext);

    const [messagesSearchInput, setMessagesSearchInput] = useState(searchTerm ? searchTerm : '');
    // const [searchGlobal, setSearchGlobal] = useState(false);

    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    // const [deleted, setDeleted] = useState(false);

    // MODALS
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const { passingErrors } = useRedirectErrors();

    // const [selectedMessagesMap, setSelectedMessagesMap] = useState({});
    // const [messagesToSend, setMessagesToSend] = useState([]);

    // HOOKS
    // const [moveMessages] = useMoveMessagesMutation();

    // const {} = useKeyboardListener();

    const [filterMessages, { data, error, loading, filterMessagesLoading, fetchMore }] = useLazyQuery(
        QUERY.FILTER_MESSAGES,
        {
            onError: res => {
                passingErrors(res);
            },
            fetchPolicy: 'network-only'
        }
    );

    // const [
    //     filterMessages,
    //     {
    //         loading: filterLoading,
    //         error: filterError,
    //         data: filterData,
    //         fetchMore: filterFetchMore,
    //         client,
    //         updateQuery
    //     }
    // ] = useLazyQuery(QUERY.FILTER_MESSAGES, {
    //     onError: res => {
    //         passingErrors(res);
    //     },
    //     fetchPolicy: 'network-only'
    // });

    // const customTypeFilter = type => {
    //     filterMessages({
    //         variables: {
    //             data: {
    //                 type: type,
    //                 folderId: '',
    //                 isTrash: showDeletedMessages
    //             }
    //         }
    //     });
    // };

    useEffect(() => {
        setSearchActive(true);
        customAnalytics.pageview('/full_search', 'SearchMessagesScreen');
        customAnalytics.search_active();
        customAnalytics.search_action({
            "search_global": searchGlobal
        });
        return () => {
            setSearchActive(false);
        };
    }, []);

    useEffect(() => {
        if (document.getElementById('searchInput')) {
            document.getElementById('searchInput').focus();
        }
        console.log('again', folder);
        // if (searchFolderFilterType.length > 0) {
        filterMessages({
            variables: {
                data: {
                    text: searchTerm,
                    type: searchFolderFilterType,
                    folderId: searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
                    isTrash: searchDeletedMessages ? true : false
                }
            }
        });
        // }
        return () => {
            // cleanup
        };
    }, [searchFolderFilterType]);

    // useEffect(() => {
    //     if (folderFilterType.length > 0) {
    //         customTypeFilter(folderFilterType);
    //     }
    //     return () => {
    //         // cleanup
    //     };
    // }, [selectedFolder, folderFilterType, searchDeletedMessages]);

    // const removeSelectedMessages = () => {
    //     setSelectedMessagesMap({});
    //     setMessagesToSend([]);
    //     setShowMultiSelect(false);
    // };

    const showDeleted = () => {
        handleMessagesSearchChange(messagesSearchInput, SHOW_TYPE.DELETED);
    };

    const showGlobal = () => {
        handleMessagesSearchChange(messagesSearchInput, SHOW_TYPE.GLOBAL);
    };

    const sendMessageAction = (action, message, index) => {
        let actionObject = {}
        if (folderFilterType && folderFilterType.length > 0) {
            actionObject["filters_active"] = [...folderFilterType];
        }
        if (searchFolderFilterType && searchFolderFilterType.length > 0) {
            actionObject["search_filters_active"] = [...searchFolderFilterType];
        }
        if (messagesSearchInput && messagesSearchInput.length > 0) {
            actionObject["search_term_length"] = messagesSearchInput.length;
        }
        if (searchGlobal) {
            actionObject["search_global"] = true;
        }
        if (searchDeletedMessages) {
            actionObject["search_trashed"] = true;
        }

        actionObject = {
            "action": action,
            "screen": "SearchMessagesScreen",
            "index_position": index,
            ...actionObject
        }
        console.log('this', actionObject)
        customAnalytics.message_action(actionObject, message);
    }

    // const selectMessage = (message, listIndex) => {
    //     if (selectedMessagesMap[message.id]) {
    //         let newSpread = [...messagesToSend];
    //         let index = newSpread.findIndex(obj => obj.id === message.id);

    //         if (index > -1) {
    //             newSpread.splice(index, 1);
    //         }

    //         setMessagesToSend([...newSpread]);

    //         delete selectedMessagesMap[message.id];
    //         setSelectedMessagesMap({
    //             ...selectedMessagesMap
    //         });
    //         sendMessageAction('Deselect', message, listIndex)
    //     } else {
    //         setSelectedMessagesMap({
    //             ...selectedMessagesMap,
    //             [message.id]: true
    //         });
    //         setMessagesToSend([...messagesToSend, message]);
    //         sendMessageAction('Select', message, listIndex)
    //     }
    // };

    const selectMessage = (message, listIndex, isShiftClick) => {


        if (enableShiftSelect && isShiftClick && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, listIndex);
            const end = Math.max(lastSelectedIndex, listIndex);
            const newSelectedMessagesMap = { ...selectedMessagesMap };
            let newMessagesToSend = [...messagesToSend];

            const messages = data.filterMessages.messages;

            for (let i = start; i <= end; i++) {
                const msg = messages[i];
                if (!newSelectedMessagesMap[msg.id]) {
                    newSelectedMessagesMap[msg.id] = true;
                    newMessagesToSend.push(msg);
                    sendMessageAction('Select', msg, i);
                }
            }

            setSelectedMessagesMap(newSelectedMessagesMap);
            setMessagesToSend(newMessagesToSend);
        } else {
            if (selectedMessagesMap[message.id]) {
                let newSpread = [...messagesToSend];
                let index = newSpread.findIndex(obj => obj.id === message.id);

                if (index > -1) {
                    newSpread.splice(index, 1);
                }

                setMessagesToSend([...newSpread]);

                const newSelectedMessagesMap = { ...selectedMessagesMap };
                delete newSelectedMessagesMap[message.id];
                setSelectedMessagesMap(newSelectedMessagesMap);
                sendMessageAction('Deselect', message, listIndex);
            } else {
                setSelectedMessagesMap({
                    ...selectedMessagesMap,
                    [message.id]: true
                });
                setMessagesToSend([...messagesToSend, message]);
                sendMessageAction('Select', message, listIndex);
            }
        }

        setLastSelectedIndex(listIndex);
        setShowMultiSelect(true);

    };

    const handleMessagesSearchChange = (value, type) => {
        setMessagesSearchInput(value);
        setSearchTerm(value);
        // here we ask if you want to search global or local
        // console.log('you should reload it', value, deleted);

        if (type === SHOW_TYPE.DELETED) {
            let tempDeleted = !searchDeletedMessages;
            customAnalytics.search_action({
                'search_trashed_messages': tempDeleted
            });
            filterMessages({
                variables: {
                    data: {
                        text: value,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
                        isTrash: tempDeleted
                    }
                }
            });
            // setShowDeletedMessages(tempDeleted);
            setSearchDeletedMessages(tempDeleted);
            // setDeleted(tempDeleted);
        } else if (type === SHOW_TYPE.GLOBAL) {
            let tempGlobal = !searchGlobal;
            customAnalytics.search_action({
                'search_global': tempGlobal
            });
            filterMessages({
                variables: {
                    data: {
                        text: value,
                        type: searchFolderFilterType,
                        folderId: tempGlobal ? '' : folder.id,
                        isTrash: searchDeletedMessages
                    }
                }
            });
            setSearchGlobal(tempGlobal);
        } else {
            filterMessages({
                variables: {
                    data: {
                        text: value,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || folder.id === HIERARCHY ? '' : folder.id,
                        isTrash: searchDeletedMessages
                    }
                }
            });
        }

        if (!loadMoreCheck) {
            // console.log('load', loadMoreCheck);
            setLoadMoreCheck(true);
        }
    };

    const renderInputOrMultiSelect = () => {
        if (Object.keys(selectedMessagesMap).length > 0) {
            return (
                <MultiSelectOptions
                    // onDeselect={() => {
                    //     removeSelectedMessages();
                    // }}
                    onSelectMove={() => {
                        setShowFoldersModal(true);
                    }}
                ></MultiSelectOptions>
            );
        } else {
            return null;
        }
    };

    const renderLoadingScreen = title => {
        return (
            <div style={{ color: 'white', backgroundColor: PRIMARY_COLOR_6, flex: 1, padding: 24 }}>
                <Loader
                    type="Puff"
                    // color="#00BFFF"
                    color={PALE_WHITE_6}
                    height={50}
                    width={50}
                // timeout={3000} //3 secs
                />
                <div style={{ fontWeight: '600', color: PALE_WHITE_6, marginTop: 8 }}>{title}</div>
            </div>
        );
    };

    const close = () => {
        setSearchActive(false);
        setMessagesToSend(undefined);
        // setShowDeletedMessages(false);
        // setSearchTerm('');
    };

    // if (data) {
    // let payload = {
    //     messages: data ? data.filterMessages.messages : [],
    //     cursor: data ? data.filterMessages.cursor : undefined
    // };
    return (
        <React.Fragment>
            <FolderWrapper
                style={{
                    backgroundColor: PRIMARY_COLOR
                    // background: `url(${background}) no-repeat center center fixed`,
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat'
                    // backgroundPosition: 'center'
                }}
                isSelecting={showMultiSelect}
            >
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <FolderHeader
                            title={`${searchGlobal ? 'Search messages in All' : 'Search messages in ' + folder.title}`}
                            // title={`Search messages`}
                            titleSize={16}
                            titleEditable={false}
                            hideFilters
                            hideSearch
                            hideMenu
                            alwaysOpen
                            // frontChildren={<Fullscreen onClick={close}>Back to {folder.title}</Fullscreen>}
                            backChildren={
                                <CloseButton onClick={close}>
                                    <IoMdClose size={24} />
                                </CloseButton>
                            }
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8, padding: '0 10px' }}>
                    <div
                        style={{
                            backgroundColor: 'rgba(255,255,255,.1)',
                            padding: 10,
                            borderRadius: 16,
                            flex: 1,
                            flexDirection: 'row',
                            display: 'flex'
                        }}
                    >
                        <CustomInput
                            id="searchInput"
                            style={{
                                color: 'rgba(255,255,255,.8)',
                                backgroundColor: 'transparent',
                                backgroundColor: 'transparent',
                                border: 0,
                                fontSize: 16,
                                color: 'white',
                                width: '100%'
                            }}
                            autoFocus
                            onKeyDown={e => {
                                if (e.keyCode === 40 || e.keyCode === 38) {
                                    e.preventDefault();
                                }
                            }}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            onFocus={e => {
                                e.target.select();
                            }}
                            value={messagesSearchInput}
                            onChange={e => handleMessagesSearchChange(e.target.value)}
                            type="text"
                            placeholder={`Search`}
                        />
                        {messagesSearchInput.length > 0 ? (
                            <ClearButton onClick={() => handleMessagesSearchChange('')}>
                                <IoMdCloseCircle size={20} />
                            </ClearButton>
                        ) : null}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', color: WHITE }}>
                        {/* <div style={{ flex: 1 }}></div> */}
                        {folder.id === STARRED || folder.id === CHECKBOXES || folder.id === HIERARCHY ? null : (
                            <GlobalButton active={searchGlobal} onClick={showGlobal}>
                                <span style={{ lineHeight: '16px' }}>Search all</span>
                                {searchGlobal ? <IoIosClose size={18}></IoIosClose> : null}
                            </GlobalButton>
                        )}

                        <DeletedButton
                            style={{ lineHeight: '16px' }}
                            active={searchDeletedMessages}
                            onClick={showDeleted}
                        >
                            <span>Search trash</span>
                            {searchDeletedMessages ? <IoIosClose size={18}></IoIosClose> : null}
                        </DeletedButton>
                    </div>
                </div>

                <MessagesList
                    messageFeatures={[SHOW_OPTIONS.SHOW_FOLDER]}
                    payload={{
                        messages: data ? data.filterMessages.messages : undefined,
                        cursor: data ? data.filterMessages.cursor : undefined,
                        folder: searchGlobal ? '' : folder
                    }}
                    isTrash={searchDeletedMessages}
                    searchFetchMore={fetchMore}
                    selectMessage={selectMessage}
                    enableShiftSelect={enableShiftSelect}
                    selectedMessagesMap={selectedMessagesMap}
                    screenName="SearchMessagesScreen"
                />
                {/* { data ? (
                  ) : null
                  } */}
                {/* {!loading ? (
                ) : (
                    renderLoadingScreen(`Loading messages...`)
                )} */}

                {renderInputOrMultiSelect()}
            </FolderWrapper>
            <SearchFoldersModal
                isOpen={showFoldersModal}
                title={`Move ${messagesToSend.length} ${pluralHelper(messagesToSend.length, 'message')} to...`}
                addNewMoveMessage
                closeModal={() => {
                    setShowFoldersModal(false);
                }}
            ></SearchFoldersModal>
        </React.Fragment>
    );
    // }
}

const CustomInput = styled.input`
    flex: 1;
    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${PALE_WHITE_6};
        opacity: 1; /* Firefox */
    }
`;

const CloseButton = styled.div`
    color: ${PALE_WHITE_4};
    cursor: pointer;
    padding: 8px;
    &:hover {
        color: ${PALE_WHITE_6};
    }
`;

const ClearButton = styled.div`
    color: ${PALE_WHITE_2};
    cursor: pointer;
    &:hover {
        color: ${PALE_WHITE_4};
    }
`;

const FolderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: ${PRIMARY_COLOR};
    position: relative;
    ${props => props.isSelecting && `
        user-select: none;
    `}
`;

const GlobalButton = styled.div`
    // margin-right: 16px;
    background-color: ${PALE_WHITE_1};
    ${props =>
        props.active &&
        css`
            background-color: ${GREEN};
        `}
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    // justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    cursor: pointer;
    height: 30px;
    margin-left: 8px;
    padding-right: ${props => (props.active ? '8px' : '12px')};
`;

const FolderTitleWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const Fullscreen = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
    }
`;

const H3 = styled.h3`
    color: white;
`;

const Messages = styled.div`
    grid-column: 3;
    grid-row: 2;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    flex: 1;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
`;

const DeletedButton = styled.div`
    // margin-right: 16px;
    background-color: ${PALE_WHITE_1};
    ${props =>
        props.active &&
        css`
            background-color: ${RED};
        `}
    border-radius: 20px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    // justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    cursor: pointer;
    height: 30px;
    padding-right: ${props => (props.active ? '8px' : '12px')};
    margin-left: 8px;
`;

export default SearchMessagesContainer;
