import { useQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';
import customAnalytics from '../util/custom-analytics';

function useDownloadFile() {
    const { refetch } = useQuery(QUERY.DOWNLOAD_FILE, { skip: true });

    const downloadFile = async (fileName, fileSize, fileDisplayName) => {
        let { data, error } = await refetch({
            data: {
                fileName: fileName
            }
        });

        if (data) {
            console.log('data is', data);
            switch (data.downloadFile.__typename) {
                case 'S3File':
                    try {
                        // Fetch the file content
                        const response = await fetch(data.downloadFile.url);
                        const blob = await response.blob();

                        // Create a blob URL
                        const blobUrl = window.URL.createObjectURL(blob);

                        // Create and trigger download
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.download = fileDisplayName;
                        document.body.appendChild(link);
                        link.click();

                        // Cleanup
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(blobUrl);
                    } catch (err) {
                        console.error('Error downloading file:', err);
                        alert('Error downloading file');
                    }
                    break;
                case 'FileDoesNotBelongToUserError':
                    alert('File does not belong to this user');
                    break;
                default:
                    alert('Server error');
                    break;
            }
        }

        if (error) {
            console.error('Error with download query', error);
        }

        customAnalytics.ui_action({
            'action': "Download File",
            "size": fileSize
        })
    };

    return downloadFile;
}

export default useDownloadFile;

// Without returning the whole result
// function useDownloadFile() {
//     const { refetch } = useQuery(QUERY.DOWNLOAD_FILE, { skip: true });

//     const downloadFile = async fileName => {
//         return refetch({
//             data: {
//                 fileName: fileName
//             }
//         });
//     };

//     return downloadFile;
// }

// export default useDownloadFile;
