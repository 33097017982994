import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import Duration from '../../../Duration';
import { IoIosPlay, IoIosPause, IoIosVolumeHigh, IoIosVolumeMute, IoIosVolumeOff, IoIosRepeat, IoMdDownload } from 'react-icons/io';
import styled from 'styled-components';

import './AudioMessage.scss';
import { FILE_BLUE, WHITE } from '../../../../appearance/Colors';
import useDownloadFile from '../../../../hooks/useDownloadFile';

function AudioMessage({ url, title, fileName, fileSize, fileDisplayName }) {
    // const title = 'something';
    // const url = 'https://www.youtube.com/watch?v=v-cmmT3YoF0';

    const downloadFile = useDownloadFile();

    const playerRef = useRef(null);

    const [playing, setPlaying] = useState(false);
    const [controls, setControls] = useState(false);
    const [light, setLight] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [loaded, setLoaded] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playbackRate, setPlaybackRate] = useState(1.0);
    const [loop, setLoop] = useState(false);
    const [pip, setPip] = useState(false);
    const [seeking, setSeeking] = useState(false);

    const handlePlayPause = () => {
        setPlaying(!playing);
    };

    const handlePlay = () => {
        console.log('onPlay');
        setPlaying(true);
    };

    const handlePause = () => {
        console.log('onPause');
        setPlaying(false);
    };

    const handleSeekMouseDown = e => {
        setSeeking(true);
    };

    const handleSeekChange = e => {
        setPlayed(parseFloat(e.target.value));
    };

    const handleSeekMouseUp = e => {
        setSeeking(false);
        playerRef.current.seekTo(parseFloat(e.target.value));
    };

    const handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!seeking) {
            setLoaded(state.loaded);
            setPlayed(state.played);
        }
    };

    const handleEnded = () => {
        console.log('onEnded');
        setPlaying(loop);
    };

    const handleDuration = duration => {
        // console.log('onDuration', duration);
        setDuration(duration);
    };

    const handleOnReady = () => {
        // console.log('onReady')
    };

    return (
        <div>
            <div>
                <ReactPlayer
                    ref={playerRef}
                    width="0"
                    height="0"
                    url={url}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={volume}
                    muted={muted}
                    onReady={handleOnReady}
                    onStart={() => console.log('onStart')}
                    onPlay={handlePlay}
                    onPause={handlePause}
                    onBuffer={() => console.log('onBuffer')}
                    onSeek={e => console.log('onSeek', e)}
                    onEnded={handleEnded}
                    // onError={e => console.log('onError', e)}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}
            >
                <div
                    style={{
                        borderRadius: 40,
                        height: 40,
                        width: 40,
                        backgroundColor: FILE_BLUE,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        handlePlayPause();
                    }}
                >
                    {!playing ? (
                        <IoIosPlay style={{ height: 20, width: 20, color: WHITE }} />
                    ) : (
                        <IoIosPause style={{ height: 20, width: 20, color: WHITE }} />
                    )}
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        marginLeft: 12,
                        maxWidth: 300
                    }}
                >
                    <div>{title}</div>
                    <input
                        style={{ marginTop: 8 }}
                        type="range"
                        min={0}
                        max={0.999999}
                        step="any"
                        value={played}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                    />
                    <div style={{
                        fontSize: 12,
                        marginTop: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <div>
                            <Duration seconds={duration * played} /> / <Duration seconds={duration} />
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                downloadFile(fileName, fileSize, fileDisplayName);
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <IoMdDownload size={16} color={FILE_BLUE}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AudioMessage;
