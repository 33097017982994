import React, { useState } from 'react';
import Hotkeys from 'react-hot-keys';

import styled from 'styled-components';

import ActionTagButton from '../../Action/ActionTagButton';

import useCheckBrowser from '../../../util/useCheckBrowser';

import { QUERY, MUTATION } from '../../../gql-operations';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../../util/context';

import { GREEN } from '../../../appearance/Colors';

import useMoveMessagesMutation from '../../../hooks/useMoveMessagesMutation';
import useTrashMessagesMutation from '../../../hooks/useTrashMessagesMutation';
import useDeleteMessagesMutation from '../../../hooks/useDeleteMessagesMutation';
import useRestoreMessagesMutation from '../../../hooks/useRestoreMessagesMutation';
import useUpdateCheckboxes from '../../../hooks/useUpdateCheckboxes';
import useUpdateStarred from '../../../hooks/useUpdateStarred';
import useNotificationSystem from '../../../components/Notifications/useNotificationSystem';

import DeleteModal from '../../Modals/DeleteModal';
import { CHECKBOXES, HIERARCHY, STARRED } from '../../../util/specialFolderEnums';
import { pluralHelper } from '../../../util/pluralHelper';
import customAnalytics from '../../../util/custom-analytics';

function MultiSelectOptions({ onDeselect, onSelectMove, specialFolder }) {
    const { Platform, PLATFORMS } = useCheckBrowser();
    const [moving, setMoving] = useState(false);

    const [addCheckbox] = useMutation(MUTATION.ADD_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                let message = res.addCheckbox;
                customAnalytics.message_edit({
                    'action': 'Task',
                    "multi_select": messagesToSend.length
                }, message)
            }
        }
    });
    const [removeCheckbox] = useMutation(MUTATION.REMOVE_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                let message = res.removeCheckbox;
                customAnalytics.message_edit({
                    'action': 'Untask',
                    "multi_select": messagesToSend.length
                }, message)
            }
        }
    });
    // const [starMessage] = useMutation(MUTATION.STAR_MESSAGE);
    const [unstarMessage] = useMutation(MUTATION.UNSTAR_MESSAGE, {
        onCompleted: res => {
            console.log('res', res)
            if (res) {
                let message = res.unstarMessage;
                customAnalytics.message_edit({
                    'action': 'Unstar',
                    "multi_select": messagesToSend.length
                }, message)
            }
        }
    });
    const [moveMessages] = useMoveMessagesMutation();
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
    const [trashMessages] = useTrashMessagesMutation();
    const [deleteMessages] = useDeleteMessagesMutation();
    const { restoreMessagesMutation } = useRestoreMessagesMutation();

    const { updateCheckboxes, spliceFromCheckboxes } = useUpdateCheckboxes();
    const { updateStarred, spliceStarred } = useUpdateStarred();
    const { showDefaultNotification } = useNotificationSystem();

    const {
        state: { selectedFolder, selectedMessage, messagesToSend, selectedMessagesMap },
        context: { setSelectedMessage, setMessagesToSend, setSelectedMessagesMap, setShowMultiSelect }
    } = React.useContext(AuthContext);

    const addChecks = () => {
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                addCheckbox({
                    variables: {
                        data: {
                            messageId: message.id
                        }
                    },
                    update: async (store, { data }) => {
                        updateCheckboxes([message]);
                    },
                    refetchQueries: [
                        {
                            query: QUERY.GET_MESSAGE,
                            variables: {
                                data: {
                                    id: message.id
                                }
                            }
                        }
                    ]
                });
            });
            removeSelectedMessages();
            // onDeselect()
        }
    };

    // const addMultipleStars = () => {
    //     if (messagesToSend) {
    //         messagesToSend.forEach(message => {
    //             starMessage({
    //                 variables: {
    //                     data: {
    //                         messageId: message.id
    //                     }
    //                 },
    //                 update: async (store, { data }) => {
    //                     updateStarred([message]);
    //                 },
    //                 refetchQueries: [
    //                     {
    //                         query: GET_MESSAGE_QUERY,
    //                         variables: {
    //                             data: {
    //                                 id: message.id
    //                             }
    //                         }
    //                     }
    //                 ]
    //             });
    //         });
    //         removeSelectedMessages();
    //         // onDeselect()
    //     }
    // };

    const removeChecks = () => {
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                removeCheckbox({
                    variables: {
                        data: {
                            messageId: message.id
                        }
                    },
                    update: async (store, { data }) => {
                        spliceFromCheckboxes([message]);
                    },
                    refetchQueries: [
                        {
                            query: QUERY.GET_MESSAGE,
                            variables: {
                                data: {
                                    id: message.id
                                }
                            }
                        }
                    ]
                });
            });
            removeSelectedMessages();
            // onDeselect()
        }
    };

    const removeStars = () => {
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                unstarMessage({
                    variables: {
                        data: {
                            messageId: message.id
                        }
                    },
                    update: async (store, { data }) => {
                        spliceStarred([message]);
                    },
                    refetchQueries: [
                        {
                            query: QUERY.GET_MESSAGE,
                            variables: {
                                data: {
                                    id: message.id
                                }
                            }
                        }
                    ]
                });
            });
            removeSelectedMessages();
            // onDeselect()
        }
    };

    const renderRemoveChecks = () => {
        let hasChecks = false;
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                if (message.isCheckbox) {
                    hasChecks = true;
                }
            });
            if (hasChecks) {
                return (
                    <ActionTagButton
                        label="Remove Task"
                        onClick={() => {
                            removeChecks();
                        }}
                        style={{ marginRight: 8 }}
                    ></ActionTagButton>
                );
            }
        }
    };

    const renderRemoveStar = () => {
        let hasStars = false;
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                if (message.isStarred) {
                    hasStars = true;
                }
            });
            if (hasStars) {
                return (
                    <ActionTagButton
                        label="Unstar"
                        onClick={() => {
                            removeStars();
                        }}
                        style={{ marginRight: 8 }}
                    ></ActionTagButton>
                );
            }
        }
    };

    const renderRestoreMessages = () => {
        let deletedAt = false;
        let deletedFolder = false;
        if (messagesToSend) {
            messagesToSend.forEach(message => {
                if (message.deletedAt) {
                    deletedAt = true;
                }
                if (message.deletedAt && message.folder.deletedAt) {
                    deletedFolder = true;
                }
            });
            if (deletedAt && deletedFolder) {
                return (
                    <ActionTagButton
                        label={`Delete permanently ${Platform === PLATFORMS.MACINTOSH ? '(⌘ + ⌫)' : '(del)'}`}
                        onClick={() => {
                            // console.log('sedn everything', messagesToSend);
                            // deleteMessages(messagesToSend, folder);
                            // removeSelectedMessages();
                            setShowDeleteMessageModal(true);
                        }}
                        style={{ marginRight: 8 }}
                    ></ActionTagButton>
                );
            } else if (deletedAt) {
                return (
                    <>
                        <ActionTagButton
                            label="Restore messages"
                            onClick={() => {
                                restoreMessageMutation();
                            }}
                            style={{ marginRight: 8 }}
                        ></ActionTagButton>
                        <ActionTagButton
                            label={`Delete permanently ${Platform === PLATFORMS.MACINTOSH ? '(⌘ + ⌫)' : '(del)'}`}
                            onClick={() => {
                                // console.log('sedn everything', messagesToSend);
                                // deleteMessages(messagesToSend, folder);
                                // removeSelectedMessages();
                                setShowDeleteMessageModal(true);
                            }}
                            style={{ marginRight: 8 }}
                        ></ActionTagButton>
                    </>
                );
            } else {
                return (
                    <ActionTagButton
                        label={`Trash ${Platform === PLATFORMS.MACINTOSH ? `(⌘ + ⌫)` : '(del)'}`}
                        onClick={() => {
                            // console.log('sedn everything', messagesToSend);
                            // deleteMessages(messagesToSend, folder);
                            // removeSelectedMessages();
                            trashAll();
                        }}
                        style={{ marginRight: 8 }}
                    ></ActionTagButton>
                );
            }
        }
    };

    const renderMoveToSpecificFolder = () => {
        let cloneMessages = [...messagesToSend];
        // removing the messages which are from current folder
        let remainingArr = cloneMessages.filter(message => message.folder.id !== selectedFolder.id);

        if (
            remainingArr.length > 0 &&
            selectedFolder.id !== HIERARCHY &&
            selectedFolder.id !== STARRED &&
            selectedFolder.id !== CHECKBOXES
        ) {
            return (
                <ActionTagButton
                    label={`Move ${remainingArr.length} to ${selectedFolder.title}`}
                    // label={`Move ${remainingArr.length} ${pluralHelper(remainingArr.length, 'message')} to ${
                    //     selectedFolder.title
                    // }`}
                    onClick={() => {
                        let toFolder = selectedFolder;
                        moveMessages(messagesToSend, toFolder, selectedFolder);
                        removeSelectedMessages();
                        // analytics here because we can track which option did they select
                        messagesToSend.forEach(message => {
                            customAnalytics.message_edit({
                                "action": "Move",
                                "multi_select": messagesToSend.length,
                                "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder",
                                "destination": toFolder.title === "Inbox" ? "Inbox" : toFolder.parent ? "Subfolder" : "HomeFolder",
                            }, message)
                        });
                        // onDeselect();
                        // deleteMessages(messagesToSend, folder);
                        // removeSelectedMessages();
                        // onSelectDelete();
                    }}
                    style={{ backgroundColor: GREEN, marginRight: 8 }}
                />
            );
        }
        return null;
    };

    const copyToClipboard = async () => {
        let list = '';
        let sorted = messagesToSend.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));
        sorted.forEach((message, index) => {
            // list.push(message.title);
            if (message.title.length > 0 && sorted.length > 1) {
                list = list + message.title;
                if (index !== sorted.length - 1) {
                    list += `\n\n`;
                }
            } else {
                list = message.title;
            }
        });
        await navigator.clipboard.writeText(list);
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 1500);
        customAnalytics.message_action({
            "action": "Copy",
            "type": "Title",
            "copy_length": list.length,
            "multi_select": messagesToSend.length
        })
        // navigator.clipboard.readText().then(res => {
        //     console.log(res);
        // });
    };

    const renderCopyTitle = () => {
        let label;
        if (copiedToClipboard) {
            label = 'Copied to clipboard';
        } else {
            label = `Copy title ${Platform === PLATFORMS.MACINTOSH ? '(⌘C)' : '(Ctr+C)'}`;
        }
        return !copiedToClipboard ? (
            <ActionTagButton
                label={label}
                onClick={() => copyToClipboard()}
                style={{ marginRight: 8 }}
            ></ActionTagButton>
        ) : (
            <ActionTagButton label={label} style={{ backgroundColor: GREEN, marginRight: 8 }}></ActionTagButton>
        );
    };

    const removeSelectedMessages = () => {
        setSelectedMessagesMap({});
        setMessagesToSend([]);
        setShowMultiSelect(false);
    };

    const deletePermanently = () => {
        // onDeselect();
        // removeSelectedMessages();
        deleteMessages(messagesToSend);
        if (selectedMessage) {
            if (messagesToSend.filter(message => message.id === selectedMessage.id)) {
                setSelectedMessage(undefined);
            }
        }
    };

    const trashAll = () => {
        // onDeselect();
        removeSelectedMessages();
        trashMessages(messagesToSend);
        showDefaultNotification(messagesToSend[0].id, 'Messages moved to trash');
    };

    const restoreMessageMutation = () => {
        // onDeselect();
        removeSelectedMessages();
        restoreMessagesMutation(messagesToSend);
    };

    const renderDeselectButton = () => {
        return (
            <ActionTagButton
                label="Deselect (Esc)"
                onClick={() => {
                    // onDeselect();
                    removeSelectedMessages();
                }}
                style={{
                    marginRight: 8,
                    border: `1px solid rgba(0,0,0,0.64)`,
                    backgroundColor: 'transparent',
                    color: 'rgba(0,0,0,0.64)'
                }}
            />
        );
    };

    const renderAddCheckButton = () => {
        return (
            <ActionTagButton
                label="Add Task"
                onClick={() => {
                    addChecks();
                }}
                style={{ marginRight: 8 }}
            />
        );
    };

    const renderMoveToButton = () => {
        return (
            <ActionTagButton
                label={`Move ${messagesToSend.length} to... ${Platform === PLATFORMS.MACINTOSH ? '(⌘J)' : '(Ctr+J)'}`}
                onClick={() => {
                    // console.log('sedn everything', messagesToSend);
                    // setShowFoldersModal(true);
                    onSelectMove(true);
                }}
                style={{ marginRight: 8 }}
            />
        );
    };

    // const renderAddStarButton = () => {
    //     return (
    //         <ActionTagButton
    //             label="Add star"
    //             onClick={() => {
    //                 addMultipleStars();
    //             }}
    //             style={{ marginRight: 8 }}
    //         ></ActionTagButton>
    //     );
    // };

    const renderActions = () => {
        if (specialFolder) {
            return (
                <>
                    {renderMoveToSpecificFolder()}
                    {renderCopyTitle()}
                    {renderMoveToButton()}
                    {renderAddCheckButton()}
                    {renderRemoveChecks()}
                    {renderRemoveStar()}
                    {renderRestoreMessages()}
                    {renderDeselectButton()}
                </>
            );
        } else {
            return (
                <>
                    {renderMoveToSpecificFolder()}
                    {renderCopyTitle()}
                    {renderMoveToButton()}
                    {renderAddCheckButton()}
                    {renderRemoveChecks()}
                    {/* {renderAddStarButton()} */}
                    {renderRemoveStar()}
                    {renderRestoreMessages()}
                    {renderDeselectButton()}
                </>
            );
        }
    };

    if (
        messagesToSend &&
        messagesToSend.length > 0 &&
        selectedMessagesMap &&
        Object.keys(selectedMessagesMap).length > 0
    ) {
        return (
            <Hotkeys
                keyName="esc, command+Backspace, ctrl+Backspace, command+J, ctrl+J, command+D, ctrl+D, command+C, ctrl+C, r, delete"
                onKeyDown={(keyName, e, handle) => {
                    if (keyName === 'esc') {
                        e.preventDefault();
                        if (showDeleteMessageModal) {
                            // do nothing
                        } else if (!moving) {
                            // onDeselect();
                            removeSelectedMessages();
                        } else {
                            setMoving(false);
                            onSelectMove(false);
                        }
                        customAnalytics.ui_action({
                            "action": "Message Deselect",
                            "shortcut_key": keyName,
                            "multi_select": messagesToSend.length
                        })
                    }
                    if (keyName === 'command+J' || keyName === 'ctrl+J') {
                        e.preventDefault();
                        onSelectMove(true);
                        setMoving(true);
                        customAnalytics.ui_action({
                            "action": "Message Move",
                            "shortcut_key": keyName,
                            "multi_select": messagesToSend.length
                        })
                    }
                    if (keyName === 'command+Backspace' || keyName === 'ctrl+Backspace' || keyName === 'delete') {
                        e.preventDefault();
                        trashAll();
                        customAnalytics.ui_action({
                            "action": "Message Trash",
                            "shortcut_key": keyName,
                            "multi_select": messagesToSend.length
                        })
                    }
                    if (keyName === 'command+D' || keyName === 'ctrl+D') {
                        e.preventDefault();
                        setShowDeleteMessageModal(true);
                        customAnalytics.ui_action({
                            "action": "Message Delete",
                            "shortcut_key": keyName,
                            "multi_select": messagesToSend.length
                        })
                    }
                    if (keyName === 'command+C' || keyName === 'ctrl+C') {
                        e.preventDefault();
                        // console.log('whats going on')
                        copyToClipboard();
                        customAnalytics.ui_action({
                            "action": "Message Copy Title",
                            "shortcut_key": keyName,
                            "multi_select": messagesToSend.length
                        })
                    }
                    // if (keyName === 'r'){
                    //     e.preventDefault();
                    //     removeChecks();
                    // }
                }}
                filter={event => {
                    return true;
                }}
            >
                <InputWrapper>{renderActions()}</InputWrapper>
                <DeleteModal
                    isOpen={showDeleteMessageModal}
                    title={`This action can't be undone`}
                    description="Delete selected messages?"
                    closeModal={response => {
                        if (response) {
                            deletePermanently();
                            removeSelectedMessages();
                        }
                        // setTimeout(async () => {
                        setShowDeleteMessageModal(false);
                        // }, 100);
                    }}
                ></DeleteModal>
            </Hotkeys>
        );
    } else {
        return null;
    }
}

const InputWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    // align-items: flex-end;
    // align-items: center;
    flex-direction: row;
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
    backdrop-filter: saturate(180%) blur(20px);
`;

export default MultiSelectOptions;
