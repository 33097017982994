import { QUERY } from '../../../gql-operations';
import { LoadDirection } from "../../../constants/loadDirection";

async function loadMoreMessages(
    folder,
    messages,
    isTrash,
    fetchMore,
    direction = LoadDirection.older,
    {
        loadingNewer,
        loadingOlder,
        setLoadingOlder,
        setLoadingNewer,
        currentFolderRef,
        isInitialLoad
    }
) {
    // Skip if initial load is in progress
    if (isInitialLoad) {
        // console.log('Skipping load more - initial load in progress');
        return;
    }

    // Skip if already loading in requested direction
    if ((direction === LoadDirection.older && loadingOlder) || (direction === LoadDirection.newer && loadingNewer)) {
        return;
    }

    const setLoading = direction === LoadDirection.older ? setLoadingOlder : setLoadingNewer;

    try {
        setLoading(true);

        const cursor = direction === LoadDirection.older
            ? messages[messages.length - 1]?.createdAt
            : messages[0]?.createdAt;

        // console.log('Loading messages:', {
        //     direction,
        //     cursor,
        //     isInitialLoad,
        //     folderId: folder.id
        // });

        await fetchMore({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: folder.id,
                    messageCursor: cursor,
                    isTrash,
                    loadDirection: direction
                }
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                // Skip update if folder changed
                if (folder.id !== currentFolderRef.current) {
                    // console.log('Skipping update - folder changed');
                    return previousResult;
                }

                if (!fetchMoreResult?.getFolderContent?.messages) {
                    return previousResult;
                }

                const previousMessages = previousResult.getFolderContent.messages.messages;
                const newMessages = fetchMoreResult.getFolderContent.messages.messages;

                // Filter out duplicate messages
                const existingIds = new Set(previousMessages.map(msg => msg.id));
                const uniqueNewMessages = newMessages.filter(msg => !existingIds.has(msg.id));

                if (uniqueNewMessages.length === 0) {
                    return previousResult;
                }

                // Combine and sort messages
                let allMessages = direction === LoadDirection.older
                    ? [...previousMessages, ...uniqueNewMessages]
                    : [...uniqueNewMessages, ...previousMessages];

                allMessages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                return {
                    getFolderContent: {
                        ...previousResult.getFolderContent,
                        messages: {
                            messages: allMessages,
                            cursor: fetchMoreResult.getFolderContent.messages.cursor,
                            __typename: previousResult.getFolderContent.messages.__typename
                        }
                    }
                };
            }
        });
    } catch (error) {
        console.error('Error loading more messages:', error);
    } finally {
        setLoading(false);
    }
}

export default loadMoreMessages;
