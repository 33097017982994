import { QUERY } from '../../../gql-operations';
import { DEFAULT_SORT_FIELD } from "../../../constants/dateField";
import { LoadDirection } from '../../../constants/loadDirection';

async function loadMoreFindMessages(
    text,
    folder,
    messages,
    isTrash,
    fetchMore,
    {
        loadingMore,
        setLoadingOlder,
        setLoadMoreCheck
    },
    sortBy = DEFAULT_SORT_FIELD,
    direction = LoadDirection.older // still not implemented
) {
    // Skip if already loading
    if (loadingMore) {
        return;
    }

    try {
        setLoadingOlder(true);
        // Get cursor based on direction
        const cursor = direction === LoadDirection.newer 
            ? messages[0]?.[sortBy]
            : messages[messages.length - 1]?.[sortBy];

        await fetchMore({
            query: QUERY.FIND_MESSAGES,
            variables: {
                data: {
                    text: text || '',
                    folderId: folder || '',
                    cursor,
                    isTrash,
                    sortBy,
                    // direction // Add direction to the query variables
                }
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult?.findMessages?.messages) {
                    setLoadMoreCheck(false);
                    return previousResult;
                }

                const previousMessages = previousResult.findMessages.messages;
                const newMessages = fetchMoreResult.findMessages.messages;

                // Filter out duplicate messages
                const existingIds = new Set(previousMessages.map(msg => msg.id));
                const uniqueNewMessages = newMessages.filter(msg => !existingIds.has(msg.id));

                if (uniqueNewMessages.length === 0) {
                    setLoadMoreCheck(false);
                    return previousResult;
                }

                // Combine messages based on direction
                let allMessages = direction === LoadDirection.newer
                    ? [...uniqueNewMessages, ...previousMessages]
                    : [...previousMessages, ...uniqueNewMessages];

                allMessages.sort((a, b) => new Date(b[sortBy]) - new Date(a[sortBy]));

                setLoadMoreCheck(false);
                return {
                    findMessages: {
                        messages: allMessages,
                        cursor: fetchMoreResult.findMessages.cursor,
                        __typename: previousResult.findMessages.__typename
                    }
                };
            }
        });
    } catch (error) {
        console.error('Error loading more find messages:', error);
    } finally {
        setLoadingOlder(false);
    }
}

export default loadMoreFindMessages;
