import React, { useState, useRef, useEffect, useReducer } from 'react';
import loadMoreMessages from './loadMoreMessages';
import loadMoreFilteredMessages from './loadMoreFilteredMessages';
import loadMoreFindMessages from './loadMoreFindMessages';

import { Waypoint } from 'react-waypoint';

import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa';

import DefaultMessage from '../../Messages/DefaultMessage';
import ImageCarousel from '../Components/ImageCarousel';
import ScrollToBottomButton from '../Components/ScrollToBottomButton';

import NewFolderCard from '../../../components/NewFolderCard';
import styled, { css } from 'styled-components';

import { AuthContext } from '../../../util/context';
import useCheckBrowser from '../../../util/useCheckBrowser';
import { useScrollToBottom } from '../../../hooks/useScrollToBottom';
import useDeleteMessagesMutation from '../../../hooks/useDeleteMessagesMutation';

import {
    PRIMARY_COLOR_4,
    SECONDARY_TEXT,
    PRIMARY_COLOR_6,
    RED,
    PALE_WHITE_6,
    PALE_WHITE_8,
    PALE_WHITE_2,
    PALE_WHITE,
    WHITE,
    RED_OP,
    GREEN_OP,
    PRIMARY_COLOR_8
} from '../../../appearance/Colors';

import CreateFolderModal from '../../Modals/CreateFolderModal';
import DeleteModal from '../../Modals/DeleteModal';
import SearchFoldersModal, { searchActions } from '../../Modals/SearchFoldersModal';

import { format, differenceInDays } from 'date-fns';

import _ from 'lodash';
import PathFolder from '../../PathFolder';
import { HIERARCHY, HISTORY } from '../../../util/specialFolderEnums';
import customAnalytics from '../../../util/custom-analytics';
import loadMessagesAroundMessage from './loadMessagesAroundMessage';
import { LoadDirection } from '../../../constants/loadDirection';
import { DateField } from '../../../constants/dateField';

function MessagesList({
    payload,
    filterMessages,
    filterMessagesCursor,
    fetchMore,
    fetchFindMore,
    filterFetchMore,
    searchFetchMore,
    selectMessage,
    enableShiftSelect,
    selectedMessagesMap,
    messageFeatures,
    isTrash,
    screenName,
    noFolderSort
}) {
    const messageContainerRef = useRef();
    const [scrollToBottom, endRef] = useScrollToBottom();
    const [currentMessage, setCurrentMessage] = useState();
    const [dropdownMessage, setDropdownMessage] = useState(undefined);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const list = useRef();
    const [showScrollToBottom, setShowScrollToBottom] = useState(false);
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    const [filteredLoadMoreCheck, setfilteredLoadMoreCheck] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showSearchFoldersModal, setShowSearchFoldersModal] = useState(false);

    const { messages, cursor, folders, parent, folder, sortBy } = payload;

    const { Browser, Platform, PLATFORMS } = useCheckBrowser();
    const [deleteMessages] = useDeleteMessagesMutation();
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    const [page, setPage] = useState(0);
    const [filteredPage, setFilteredPage] = useState(0);
    const [loader, setLoader] = useState(false);
    const [initialLoader, setInitialLoader] = useState(true);

    const {
        state: {
            selectedFolder,
            selectedMessage,
            folderFilterType,
            holdingKeys,
            keys,
            showMultiSelect,
            homeFolder,
            searchTerm,
            searchActive,
            searchFolderFilterType
        },
        context: {
            setSelectedFolder,
            setSelectedMessage,
            setFolderFilter,
            setMessagesToSend,
            setSelectedMessagesMap,
            setShowMultiSelect,
            setSearchFolderFilter,
        }
    } = React.useContext(AuthContext);

    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [loadingOlder, setLoadingOlder] = useState(false);
    const [loadingNewer, setLoadingNewer] = useState(false);

    const currentFolderRef = useRef(null);

    useEffect(() => {
        if (payload?.folder?.id !== currentFolderRef.current) {
            setIsInitialLoad(true);
            setLoadingOlder(false);
            setLoadingNewer(false);
            currentFolderRef.current = payload?.folder?.id;
        }
    }, [payload?.folder?.id]);

    useEffect(() => {
        if (isInitialLoad && payload?.messages?.length > 0) {
            setIsInitialLoad(false);
        }
    }, [payload?.messages]);

    const loadMore = async (direction = LoadDirection.older) => {
        // this is in case that for Hierarchy folder because it's a different fetch
        // and we're searching for text and folder
        setLoader(true);
        if (searchActive && searchFetchMore) {
            loadMoreFilteredMessages(
                searchTerm,          // search text
                searchFolderFilterType,  // message type
                folder,       // folder object
                messages,    // current filtered messages array
                isTrash,            // boolean
                searchFetchMore,     // Apollo fetchMore function
                {   // Pass the state object correctly
                    loadingOlder,
                    setLoadingOlder,
                    setLoadMoreCheck: setfilteredLoadMoreCheck
                },
                sortBy,       // optional sort field
                direction
            );
        } else if (folderFilterType && folderFilterType.length > 0 && filterMessages) {
            loadMoreFilteredMessages(
                searchActive ? searchTerm : '',          // search text
                searchActive ? searchFolderFilterType : folderFilterType,  // message type
                folder,       // folder object
                filterMessages,    // current filtered messages array
                isTrash,            // boolean
                searchActive ? searchFetchMore : filterFetchMore,     // Apollo fetchMore function
                {   // Pass the state object correctly
                    loadingOlder,
                    setLoadingOlder,
                    setLoadMoreCheck: setfilteredLoadMoreCheck
                },
                sortBy,       // optional sort field
                direction
            );
        } else if (fetchFindMore) {
            let text = ''; // searchTerm ? searchTerm : '';
            let folder = ''; // searchGlobal || selectedFolder.id === HIERARCHY ? '' : selectedFolder.id;
            // loadMoreFindMessages(
            //     text, folder, messages[messages.length - 1]?.[sortBy], isTrash, fetchFindMore, setLoadMoreCheck, sortBy);
            loadMoreFindMessages(
                text,
                folder,
                messages,
                isTrash,
                fetchFindMore,
                {
                    loadingOlder,
                    setLoadingOlder,
                    setLoadMoreCheck
                },
                sortBy,
                direction
            );
        } else {
            await loadMoreMessages(
                payload.folder,
                messages,
                isTrash,
                fetchMore,
                direction,
                {
                    loadingNewer,
                    loadingOlder,
                    setLoadingOlder,
                    setLoadingNewer,
                    currentFolderRef,
                    isInitialLoad
                }
            );
        }
        setLoader(false);
    };

    useEffect(() => {
        if (Boolean(messages) && folderFilterType.length === 0) {
            setInitialLoader(false);
        }
        if (Boolean(filterMessages) && folderFilterType.length > 0) {
            setInitialLoader(false);
        }
        if (Boolean(messages) && searchFolderFilterType.length > 0) {
            setInitialLoader(false);
        }
    }, [messages, filterMessages, folderFilterType, searchFolderFilterType]);

    useEffect(() => {
        // with every folder switch these need to reset
        setInitialLoader(true);
        setLoadMoreCheck(true);
        setfilteredLoadMoreCheck(true);
    }, [selectedFolder, folderFilterType, searchFolderFilterType]);

    useEffect(() => {
        // just so the titleRef can rerender
        forceUpdate();
        if (selectedMessage && fetchMore) {
            // Check if we're opening a message from a different folder
            if (selectedMessage && selectedMessage.loadContext) {
                if (selectedFolder !== selectedMessage.folder) {
                    setSelectedFolder(selectedMessage.folder);
                }
                // problem here with fetchmore, msg jumping
                loadMessagesAroundMessage(selectedMessage, fetchMore);
            }

        }
    }, [selectedMessage, fetchMore]);

    const loadMoreFiltered = () => {
        loadMoreFilteredMessages(
            searchActive ? searchTerm : '',
            searchActive ? searchFolderFilterType : folderFilterType,
            folder,
            filterMessages,
            isTrash,
            searchActive ? searchFetchMore : filterFetchMore,
            {   // Pass the state object correctly
                loadingOlder,
                setLoadingOlder: setLoader,
                setLoadMoreCheck: setfilteredLoadMoreCheck
            },
            sortBy
        );
    };

    // const renderMultiSelectButtons = message => {
    //     if (Object.keys(selectedMessagesMap).length > 0 || showMultiSelect) {
    //         return (
    //             <div
    //                 style={{ cursor: 'pointer' }}
    //                 onClick={() => {
    //                     selectMessage(message);
    //                 }}
    //             >
    //                 {selectedMessagesMap[message.id] ? (
    //                     <FaRegCheckCircle style={{ height: 20, width: 20, color: 'white' }}></FaRegCheckCircle>
    //                 ) : (
    //                     <FaRegCircle style={{ height: 20, width: 20, color: 'white' }}></FaRegCircle>
    //                 )}
    //             </div>
    //         );
    //     }
    //     return null;
    // };

    const renderFolders = () => {
        let margin = 0;
        if (isTrash) {
            if (payload.path && payload.path.length === 0) {
                margin = 8;
            }
            if (folders && folder.length > 0) {
                margin = 8;
            }
        }
        if (selectedFolder.id === HISTORY) {
            margin = 16;
        }
        if (folders) {
            const localSort = [...folders];
            if (!noFolderSort) {
                localSort.sort((a, b) => a.title.localeCompare(b.title, 0, { caseFirst: 'lower' }));
            }
            return (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: 40,
                            transform:
                                Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                            marginBottom: margin
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexFlow: 'row wrap',
                                maxWidth: 500
                            }}
                        >
                            {localSort.map(folder => {
                                return (
                                    <div
                                        key={folder.id}
                                        style={{
                                            backgroundColor: PRIMARY_COLOR_8,
                                            borderRadius: 8,
                                            marginBottom: 4,
                                            marginRight: 8,
                                            maxWidth: 150,
                                            // transition: 'backdrop-filter 0.2s',
                                            // backdropFilter: 'saturate(180%) blur(20px)'
                                        }}
                                        onClick={() => {
                                            setSelectedFolder(folder);
                                            customAnalytics.subfolder_open();
                                        }}
                                    >
                                        <NewFolderCard item={{ data: folder }} disableRightIcon></NewFolderCard>
                                    </div>
                                );
                            })}
                            {!selectedFolder.deletedAt &&
                                !isTrash &&
                                selectedFolder &&
                                selectedFolder.id !== (homeFolder && homeFolder.id) &&
                                selectedFolder.id !== HISTORY ? (
                                <div
                                    key={selectedFolder.id}
                                    style={{
                                        backgroundColor: PRIMARY_COLOR_8,
                                        borderRadius: 8,
                                        marginBottom: 4,
                                        marginRight: 8,
                                        // transition: 'backdrop-filter 0.2s',
                                        // backdropFilter: 'saturate(180%) blur(20px)'
                                    }}
                                    onClick={() => {
                                        setShowCreateFolderModal(true);
                                    }}
                                >
                                    <NewFolderCard
                                        item={{ data: { id: selectedFolder.id, title: '+ Add folder' } }}
                                        disableRightIcon
                                        disableDrop
                                        disableDrag
                                    ></NewFolderCard>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            );
        }
    };

    const renderParentAndPath = () => {
        let margin = 0;
        if (isTrash) {
            if (payload.path && payload.path.length > 0) {
                margin = 8;
            }
        }
        if (payload.path && payload.path.length > 0) {
            return (
                <ParentFolderWrapper
                    style={{
                        transform:
                            Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                        marginBottom: margin
                    }}
                >
                    {payload.path.map((folder, index) => {
                        return (
                            <div key={folder.id} style={{ display: 'flex', alignItems: 'center' }}>
                                <PathFolder
                                    folder={folder}
                                    onClick={() => {
                                        // here we is a full parent object from the server
                                        setSelectedFolder(folder);
                                    }}
                                ></PathFolder>

                                {index < payload.path.length - 1 ? (
                                    <span style={{ marginLeft: 2, marginRight: 2, color: 'white' }}>/</span>
                                ) : null}
                                {index === payload.path.length - 1 ? (
                                    <div style={{ color: 'white' }}>
                                        <span>/</span>
                                        <CurrentFolder>{payload.folder.title}</CurrentFolder>
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </ParentFolderWrapper>
            );
        }
        return null;
    };

    const renderDateSeparator = (dateSeparator, i) => {
        const shortDate = format(dateSeparator, 'dd MMM');
        const fullDate = format(dateSeparator, 'dd MMM yyyy');
        const separatorId = `separator-${sortBy}-${dateSeparator.getTime()}`;
        return (
            <SeparatorContainer browser={Browser} platform={Platform} style={{ zIndex: -i }} key={separatorId}>
                <div
                    style={{
                        backgroundColor: PRIMARY_COLOR_4,
                        borderRadius: 20,
                        padding: '2px 8px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: 4,
                        backdropFilter: 'saturate(180%) blur(20px)',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        const input = document.getElementById(`date-picker-${i}`);
                        if (input) input.showPicker();
                        customAnalytics.ui_action({
                            "action": "Open Date Picker",
                            "screen": "MessageScreen"
                        })
                    }}
                >
                    <span
                        title={fullDate}
                        style={{ color: PALE_WHITE_8, fontWeight: '500', fontSize: 12 }}
                    >
                        {shortDate}
                    </span>
                    <input
                        id={`date-picker-${i}`}
                        type="date"
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            // Check if a complete date was selected (YYYY-MM-DD)
                            // TODO BETTER PICKER
                            if (selectedDate && selectedDate.split('-').length === 3) {
                                loadMessagesAroundMessage(
                                    new Date(selectedDate),
                                    fetchMore,
                                    {
                                        folderId: payload.folder.id,
                                        isTrash
                                    }
                                );
                            }
                        }}
                        style={{
                            position: 'absolute',
                            opacity: 0,
                            width: 0,
                            height: 0,
                            padding: 0,
                            margin: 0,
                            border: 'none'
                        }}
                    />
                </div>
            </SeparatorContainer>
        );
    };

    const renderDateSection = (message, i) => {
        let date = format(new Date(message.createdAt), 'HH:mm');
        // if (messageContainerRef.current && messageContainerRef.current.offsetWidth > 500) {
        return (
            <div
                onClick={e => {
                    e.stopPropagation();
                    selectMessage(message, i);
                }}
                style={{
                    color: PALE_WHITE_6,
                    marginRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                    // minWidth: 100,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span>{date}</span>
            </div>
        );
        // }
    };

    const renderMessages = (messages, filter) => {
        // First create the array with separators
        let tempMessages = [];
        if (Boolean(messages) && messages.length > 0) {
            let i = 0;
            let messageCount = messages.length;

            // Helper function to get the correct date field based on folder/sorting
            const getDateField = (message) => {
                switch (selectedFolder?.id) {
                    case HISTORY:
                        return new Date(message.lastViewedAt);
                    // case SOME_OTHER_SPECIAL_FOLDER:
                    //     return new Date(message.someOtherDate);
                    default:
                        return new Date(message.createdAt);
                }
            };

            while (i < messageCount) {
                let previous = messages[i - 1];
                let current = messages[i];

                let currentDate = getDateField(current);
                let currentDate00 = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    0,
                    0,
                    0
                );

                if (previous) {
                    let previousDate = getDateField(previous);
                    let previousDate00 = new Date(
                        previousDate.getFullYear(),
                        previousDate.getMonth(),
                        previousDate.getDate(),
                        23,
                        59,
                        0
                    );

                    let previousDuration = differenceInDays(previousDate00, currentDate00);

                    if (previousDuration !== 0) {
                        tempMessages.push({
                            separator: true,
                            date: previousDate,
                            id: `separator-${previousDate.getTime()}`
                        });
                    }
                }

                // Pass the real message index from the original array
                tempMessages.push({
                    separator: false,
                    message: current,
                    index: i
                });

                i += 1;
            }

            // Add final separator if there are messages
            if (messageCount > 0) {
                const lastMessage = messages[messageCount - 1];
                const lastDate = getDateField(lastMessage);
                tempMessages.push({
                    separator: true,
                    date: lastDate,
                    id: `separator-${lastDate.getTime()}-last`
                });
            }

            // Render the items with their proper indices
            return tempMessages.map((item) => {
                if (item.separator) {
                    return renderDateSeparator(item.date, item.id);
                } else {
                    return renderMessageContainer(item.message, item.index, filter);
                }
            });
        } else {
            if (initialLoader) {
                return (
                    <LoadingMessages browser={Browser} platform={Platform}>
                        Loading messages...
                    </LoadingMessages>
                );
            } else {
                return renderNoMessages();
            }
        }
    };

    const renderMessageContainer = (message, i, filter) => {
        // Ensure i is a valid number, default to 0 if not
        const zIndex = typeof i === 'number' ? -i : 0;

        return (
            <div
                id={message.id}
                key={message.id}
                style={{
                    transform: Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
                    zIndex: zIndex
                }}
            >
                {/* Add waypoint before the message for first message */}
                {i === 0 && renderWaypoint(i)}

                <MessageContainer
                    ref={messageContainerRef}
                    key={message.id}
                    style={selectedMessagesMap[message.id] ? { backgroundColor: GREEN_OP, zIndex: zIndex } : null}
                    onClick={(e) => {
                        if (!holdingKeys && !keys.Meta) {
                            if (Object.keys(selectedMessagesMap).length > 0 || showMultiSelect) {
                                selectMessage(message, i, enableShiftSelect ? e.shiftKey : false);
                            } else {
                                setSelectedMessage(message);
                            }
                        }
                    }}
                >
                    <DefaultMessage
                        index={i}
                        screenName={screenName}
                        message={message}
                        // multipleSelect={() => {
                        //     selectMessage(message, i);
                        // }}
                        imagePreview={message => {
                            setLightboxIsOpen(true);
                            setCurrentMessage(message);
                        }}
                        messageFeatures={messageFeatures}
                        showDeleteModal={message => {
                            if (message) {
                                setDropdownMessage(message);
                                setShowDeleteModal(true);
                            }
                        }}
                        showMoveMessageModal={message => {
                            if (message) {
                                setDropdownMessage(message);
                                setShowSearchFoldersModal(true);
                            }
                        }}
                        openMenu={val => {
                            let element = document.getElementById(message.id);
                            if (val && element.style) {
                                element.style.zIndex = 50;
                            } else {
                                element.style.zIndex = null;
                            }
                        }}
                    // selected={selectedMessagesMap[message.id]}
                    // isDropped={isDropped('some crazy name')}
                    />

                    {/* <div style={{ flex: 1 }}></div> */}
                    {/* {renderMultiSelectButtons(message)} */}
                    {renderDateSection(message, i)}
                </MessageContainer>
                {filter ? renderCustomWaypoint(i) : i === messages.length - 1 && renderWaypoint(i)}
            </div>
        );
    };

    // const renderDefaultMessage = message => {
    //     return (
    // <DefaultMessage
    //     key={message.id}
    //     message={message}
    //     multipleSelect={() => {
    //         selectMessage(message, i);
    //     }}
    //     imagePreview={message => {
    //         setLightboxIsOpen(true);
    //         setCurrentMessage(message);
    //     }}
    //     messageFeatures={messageFeatures}
    //     // selected={selectedMessagesMap[message.id]}
    //     // isDropped={isDropped('some crazy name')}
    // />;
    //     );
    // };

    const renderLoader = () => {
        // if (messages?.length > 10) {
        if (loadingOlder) {
            return (
                <LoadingMessages browser={Browser} platform={Platform}>
                    Loading older messages...
                </LoadingMessages>
            );
        }
        if (loadingNewer) {
            return (
                <LoadingMessages browser={Browser} platform={Platform}>
                    Loading newer messages...
                </LoadingMessages>
            );
        }
        // }
        return null;
    };

    const renderFilterLoader = () => {
        if (filteredLoadMoreCheck) {
            if (filterMessages && filterMessages.length > 10) {
                return (
                    <LoadingMessages browser={Browser} platform={Platform}>
                        Loading messages...
                    </LoadingMessages>
                );
            }
        }
    };

    const renderNoMessages = () => {
        return (
            <TurnOffContainer
                browser={Browser}
                platform={Platform}
                style={{
                    marginLeft: 40,
                    fontWeight: '600',
                    color: SECONDARY_TEXT,
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                No messages here
                {searchActive && searchFolderFilterType.length > 0 ? (
                    <ClearFilters
                        onClick={() => {
                            setSearchFolderFilter([]);
                        }}
                    >
                        <span>Clear filters</span>
                        {/* <TurnOff>(turn off)</TurnOff> */}
                    </ClearFilters>
                ) : null}
                {!searchActive && folderFilterType.length > 0 ? (
                    <ClearFilters
                        onClick={() => {
                            setFolderFilter([]);
                        }}
                    >
                        <span>Clear filters</span>
                        {/* <TurnOff>(turn off)</TurnOff> */}
                    </ClearFilters>
                ) : null}
            </TurnOffContainer>
        );
    };

    const renderCustomContent = () => {
        if (searchActive) {
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(messages)}
                    {/* {filterMessages && filterMessages.length
                        ? filterMessages.map((message, i) => {
                              // check for aws as well
                              let filter = true;
                              return renderMessageContainer(message, i, filter);
                          })
                        : renderNoMessages()} */}
                    {renderLoader()}
                </Messages>
            );
        } else if (folderFilterType && folderFilterType.length > 0) {
            // sorting to be able to send the message to the bottom of the change
            // becase of firefox magic with transform
            // filterMessages.sort(function compare(a, b) {
            //     var dateA = new Date(a.createdAt);
            //     var dateB = new Date(b.createdAt);
            //     return dateB - dateA;
            // });
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(filterMessages, true)}
                    {/* {filterMessages && filterMessages.length
                        ? filterMessages.map((message, i) => {
                              // check for aws as well
                              let filter = true;
                              return renderMessageContainer(message, i, filter);
                          })
                        : renderNoMessages()} */}
                    {renderFilterLoader()}
                </Messages>
            );
        } else {
            // sorting to be able to send the message to the bottom of the change
            // becase of firefox magic with transform
            // messages.sort(function compare(a, b) {
            //     var dateA = new Date(a.createdAt);
            //     var dateB = new Date(b.createdAt);
            //     return dateB - dateA;
            // });
            return (
                <Messages id="messagesList" browser={Browser} platform={Platform} ref={list}>
                    {/* reference for the end of the list */}
                    <div ref={endRef} />
                    {renderParentAndPath()}
                    {renderFolders()}
                    {renderMessages(messages)}
                    {/* {messages && messages.length
                        ? messages.map((message, i) => {
                              // check for aws as well
                              return renderMessageContainer(message, i);
                          })
                        : renderNoMessages()} */}
                    {renderLoader()}
                </Messages>
            );
        }
    };

    const renderWaypoint = i => {
        if (!isInitialLoad && messages && messages.length > 0) {
            const OFFSET = 1000;

            if (i === 0) {
                return (
                    <Waypoint
                        onEnter={() => {
                            if (!loadingNewer && !isInitialLoad && (loadMoreCheck || filteredLoadMoreCheck)) {
                                loadMore(LoadDirection.newer);
                            }
                        }}
                        topOffset={OFFSET}
                    // fireOnRapidScroll={true}
                    />
                );
            }

            if (messages && i === messages.length - 1) {
                return (
                    <Waypoint
                        onEnter={() => {
                            if (!loadingOlder && !isInitialLoad && (loadMoreCheck || filteredLoadMoreCheck)) {
                                loadMore(LoadDirection.older);
                            }
                        }}
                        bottomOffset={OFFSET}
                    // fireOnRapidScroll={true}
                    />
                );
            }
        }
        return null;
    };

    const renderCustomWaypoint = i => {
        if (i === filterMessages.length - 3) {
            return (
                <Waypoint
                    onEnter={() => {
                        if (!loader) {
                            setFilteredPage(filteredPage + 1);
                            loadMoreFiltered();
                        }
                    }}
                />
            );
        } else {
            return null;
        }
    };

    const renderImageCarousel = () => {
        let carouselMessages;
        if (folderFilterType && folderFilterType.length > 0 && filterMessages) {
            carouselMessages = filterMessages;
        } else {
            carouselMessages = messages;
        }
        return (
            <ImageCarousel
                isVisible={lightboxIsOpen}
                data={carouselMessages}
                currentMessage={currentMessage}
                closeModal={() => {
                    setLightboxIsOpen(false);
                }}
            ></ImageCarousel>
        );
    };

    const removeSelectedMessages = () => {
        // setSelectedMessagesMap({});
        // setMessagesToSend([]);
        setShowMultiSelect(false);
    };

    const deletePermanently = () => {
        // onDeselect();
        removeSelectedMessages();
        deleteMessages([dropdownMessage]);
        if (selectedMessage && selectedMessage.id === dropdownMessage.id) {
            setSelectedMessage(undefined);
        }
    };

    const renderModals = () => {
        return (
            <>
                <CreateFolderModal
                    currentFolder={selectedFolder}
                    isOpen={showCreateFolderModal}
                    subfolder
                    closeModal={value => {
                        setShowCreateFolderModal(false);
                        // if (value){
                        //     setSelectedFolder(value);
                        //     if (document.getElementById(value.id)) {
                        //         document.getElementById(value.id).scrollIntoView();
                        //     }
                        // }
                    }}
                />
                {/* MODALS */}
                <DeleteModal
                    isOpen={showDeleteModal}
                    title={`This action can't be undone`}
                    description="This message will be deleted and you will not going to be able to access it's content any longer."
                    closeModal={response => {
                        setShowDeleteModal(false);
                        if (response) {
                            deletePermanently();
                        }
                        // setTimeout(async () => {
                        // }, 100);
                    }}
                ></DeleteModal>

                <SearchFoldersModal
                    isOpen={showSearchFoldersModal}
                    title={`Move message to...`}
                    addNewMoveMessage
                    // action={searchActions.MOVE_FOLDER}
                    dropdownMessage={dropdownMessage}
                    closeModal={toFolder => {
                        setShowSearchFoldersModal(false);
                        // if (toFolder) {
                        //     moveMessages([dropdownMessage], toFolder, selectedFolder);
                        // }
                    }}
                ></SearchFoldersModal>
            </>
        );
    };

    const _handleScroll = ev => {
        // console.log('Scrolling!', ev);
        // console.log('Scrolling!', ev.srcElement.scrollTop);
        if (Browser !== 'Firefox' || Platform !== PLATFORMS.MACINTOSH) {
            if (ev.srcElement.scrollTop < -200) {
                if (!showScrollToBottom) {
                    setShowScrollToBottom(true);
                }
            } else {
                setShowScrollToBottom(false);
            }
        } else {
            if (ev.srcElement.scrollTop > 200) {
                if (!showScrollToBottom) {
                    setShowScrollToBottom(true);
                }
            } else {
                setShowScrollToBottom(false);
            }
        }
        // console.log('Scrolling!', ev.scrollTop);
    };

    React.useEffect(() => {
        list.current.addEventListener('scroll', _handleScroll);
        console.log('added scroll listener');
        return () => {
            if (list.current) {
                list.current.removeEventListener('scroll', _handleScroll);
                console.log('removed scroll listener');
            }
        };
    }, []);

    const renderScrollToBottom = () => {
        // if (document.getElementById('messagesList')) {
        // console.log(document.getElementById('messagesList').scrollHeight);
        // console.log(document.getElementById('messagesList').offsetTop);
        // console.log(document.getElementById('messagesList').offsetHeight);
        // let elementHeight = document.getElementById('messagesList').offsetHeight;
        // let scrollHeight = document.getElementById('messagesList').scrollHeight;

        // console.log('ieiql', list.current.scrollTop);

        return (
            <ScrollToBottomButton
                show={showScrollToBottom}
                onClick={() => {
                    console.log('do you scroll');
                    scrollToBottom();
                }}
            />
        );

        // }
    };

    return (
        <>
            {renderCustomContent()}
            {renderImageCarousel()}
            {renderScrollToBottom()}

            {renderModals()}
        </>
    );
}

const Messages = styled.div`
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
    flex-direction: column-reverse;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            flex-direction: column;
            transform: scaleY(-1);
        `}
    padding-left: 10px;
    padding-right: 10px;
`;

const SeparatorContainer = styled.div`
    display: flex;
    // flex: 1;
    justify-content: center;
    margin-bottom: 16px;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}

        > div {
        transition: background-color 0.2s ease;
        
        &:hover {
            background-color: ${PRIMARY_COLOR_6} !important;
        }
        
        > span {
            transition: color 0.2s ease;
            
            &:hover {
                color: ${WHITE} !important;
            }
        }
    }
`;

const LoadingMessages = styled.div`
    margin-left: 40px;
    font-weight: 600;
    margin-bottom: 16px;
    color: ${SECONDARY_TEXT};
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: rgba(255, 255, 255, 0.05);
    }
`;

const ParentFolderWrapper = styled.div`
    margin-left: 40px;
    display: flex;
    flex-direction: row;
`;

const CurrentFolder = styled.span`
    margin-left: 2px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
`;

const TurnOffContainer = styled.div`
    margin-bottom: 16px;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
            margin-top: 16px;
            margin-bottom: 0px;
        `}
`;

const TurnOff = styled.span`
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        color: ${RED}!important;
    }
`;

const ClearFilters = styled.div`
    margin-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    background-color: ${RED_OP};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
    }
`;

export default MessagesList;
