import React, { useState, useEffect, useRef } from 'react';
import { QUERY } from '../../../gql-operations';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import styled, { css } from 'styled-components';

import { SHOW_OPTIONS } from '../../Messages/DefaultMessage';
import SearchMessagesModal from '../../Modals/SearchMessagesModal';
import SearchFoldersModal from '../../Modals/SearchFoldersModal';

import MessagesList from '../Components/MessagesList';
import MultiSelectOptions from '../Components/MultiSelectOptions';
import FilterComponent from '../Components/FilterComponent';
import ScrollToBottomButton from '../Components/ScrollToBottomButton';
import FolderHeader from '../Components/FolderHeader';

// import useMoveMessagesMutation from '../../../hooks/useMoveMessagesMutation';

import useKeyboardListener from '../../../hooks/useKeyboardListener';

import { AuthContext } from '../../../util/context';
import { pluralHelper } from '../../../util/pluralHelper';
import HideDeletedMessagesComponent from '../HideDeletedMessagesComponent';

import useRedirectErrors from '../../../errors/useRedirectErrors';
import { PRIMARY_COLOR, PALE_WHITE_6, PRIMARY_COLOR_6, WHITE, PALE_WHITE_2, PALE_WHITE_4, PRIMARY_COLOR_4 } from '../../../appearance/Colors';

import Loader from 'react-loader-spinner';
import customAnalytics from '../../../util/custom-analytics';
import { HISTORY } from '../../../util/specialFolderEnums';
import { DEFAULT_SORT_FIELD, HISTORY_SORT_FIELD } from '../../../constants/dateField';
import { sortBy } from 'lodash';
import loadMoreFilteredFolders from '../Components/loadMoreFilteredFolders';
import { Waypoint } from 'react-waypoint';
import useCheckBrowser from '../../../util/useCheckBrowser';

const FOLDER_SCREENS = {
    HISTORY: 'FolderHistory',
    ALL: 'FolderAll'
};

function HierarchyFolderContainer({ enableShiftSelect = true }) {
    const {
        state: { selectedFolder, folderFilterType, showDeletedMessages, messagesToSend, selectedMessagesMap, showMultiSelect },
        context: {
            setSelectedMessage,
            setShowDeletedMessages,
            setMessagesToSend,
            setSelectedMessagesMap,
            setShowMultiSelect,
            setSelectedFolder
        }
    } = React.useContext(AuthContext);

    // MODALS
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [showFolders, setShowFolders] = useState(false);
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    const [loadingOlder, setLoadingOlder] = useState(false);
    const [loadingNewer, setLoadingNewer] = useState(false);
    const { passingErrors } = useRedirectErrors();

    // const [selectedMessagesMap, setSelectedMessagesMap] = useState({});
    // const [messagesToSend, setMessagesToSend] = useState([]);

    // HOOKS
    // const [moveMessages] = useMoveMessagesMutation();

    // const {} = useKeyboardListener();

    const { Browser, Platform, PLATFORMS } = useCheckBrowser();

    const [findMessages, { data, error, loading, findMessagesLoading, fetchMore }] = useLazyQuery(QUERY.FIND_MESSAGES, {
        onError: res => {
            passingErrors(res);
        },
        fetchPolicy: 'network-only'
    });

    const [findFolders, { data: findFolderData, error: findFolderError, loading: findFolderLoading, fetchMore: fetchMoreFolders }] =
        useLazyQuery(QUERY.FIND_FOLDERS, {
            onError: res => {
                passingErrors(res);
            },
            fetchPolicy: 'network-only'
        });


    const [
        filterMessages,
        {
            loading: filterLoading,
            error: filterError,
            data: filterData,
            fetchMore: filterFetchMore,
            client,
            updateQuery
        }
    ] = useLazyQuery(QUERY.FILTER_MESSAGES, {
        onError: res => {
            passingErrors(res);
        },
        fetchPolicy: 'network-only'
    });

    const customTypeFilter = type => {
        filterMessages({
            variables: {
                data: {
                    text: '',
                    type: type,
                    folderId: '',
                    isTrash: showDeletedMessages,
                    sortBy: HISTORY_SORT_FIELD
                }
            }
        });
    };

    const renderWaypoint = (i, folders) => {
        if (folders.length > 2) {
            if (i === folders.length - 3) {
                return <Waypoint onEnter={() => loadMoreFolders()} />;
            }
        }
        return null;
    };

    useEffect(() => {
        customAnalytics.pageview('/all_view', 'FolderLastViewed');
    }, [selectedFolder]);

    useEffect(() => {
        findMessages({
            variables: {
                data: {
                    text: '',
                    folderId: '',
                    isTrash: showDeletedMessages,
                    sortBy: HISTORY_SORT_FIELD
                }
            }
        });
        // Loading in SearchFoldersModal better
        findFolders({
            variables: {
                data: {
                    text: '',
                    sortBy: HISTORY_SORT_FIELD
                }
            }
        });
        return () => {
            // cleanup
        };
    }, [showDeletedMessages, selectedFolder]);

    useEffect(() => {
        if (folderFilterType.length > 0) {
            customTypeFilter(folderFilterType);
        }
        return () => {
            // cleanup
        };
    }, [selectedFolder, folderFilterType, showDeletedMessages]);

    // const removeSelectedMessages = () => {
    //     setSelectedMessagesMap({});
    //     setMessagesToSend([]);
    //     setShowMultiSelect(false);
    // };

    const sendMessageAction = (action, message, index) => {
        let actionObject = {}
        if (folderFilterType && folderFilterType.length > 0) {
            actionObject["filters_active"] = [...folderFilterType];
        }
        if (showDeletedMessages) {
            actionObject["show_trashed"] = true;
        }
        actionObject = {
            "action": action,
            "screen": 'FolderLastViewed',
            "index_position": index,
            ...actionObject
        }
        console.log('this', actionObject)
        customAnalytics.message_action(actionObject, message);
    }


    const selectMessage = (message, listIndex, isShiftClick) => {


        if (enableShiftSelect && isShiftClick && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, listIndex);
            const end = Math.max(lastSelectedIndex, listIndex);
            const newSelectedMessagesMap = { ...selectedMessagesMap };
            let newMessagesToSend = [...messagesToSend];

            // Use the correct message array based on whether we're filtering
            const messages = folderFilterType.length > 0 && filterData
                ? filterData.filterMessages.messages
                : data.findMessages.messages;

            for (let i = start; i <= end; i++) {
                const msg = messages[i];
                if (msg && !newSelectedMessagesMap[msg.id]) {
                    newSelectedMessagesMap[msg.id] = true;
                    newMessagesToSend.push(msg);
                    sendMessageAction('Select', msg, i);
                }
            }

            setSelectedMessagesMap(newSelectedMessagesMap);
            setMessagesToSend(newMessagesToSend);
        } else {
            if (selectedMessagesMap[message.id]) {
                let newSpread = [...messagesToSend];
                let index = newSpread.findIndex(obj => obj.id === message.id);

                if (index > -1) {
                    newSpread.splice(index, 1);
                }

                setMessagesToSend([...newSpread]);

                const newSelectedMessagesMap = { ...selectedMessagesMap };
                delete newSelectedMessagesMap[message.id];
                setSelectedMessagesMap(newSelectedMessagesMap);
                sendMessageAction('Deselect', message, listIndex);
            } else {
                setSelectedMessagesMap({
                    ...selectedMessagesMap,
                    [message.id]: true
                });
                setMessagesToSend([...messagesToSend, message]);
                sendMessageAction('Select', message, listIndex);
            }
        }

        setLastSelectedIndex(listIndex);
        setShowMultiSelect(true);

    };

    const renderInputOrMultiSelect = () => {
        if (Object.keys(selectedMessagesMap).length > 0) {
            return (
                <MultiSelectOptions
                    // onDeselect={() => {
                    //     removeSelectedMessages();
                    // }}
                    onSelectMove={() => {
                        setShowFoldersModal(true);
                    }}
                ></MultiSelectOptions>
            );
        } else {
            // better solution in the searchFoldersModal
            return (
                null
                // <ToggleWrapper>
                //     <ToggleButton
                //         active={!showFolders}
                //         onClick={() => setShowFolders(false)}
                //     >
                //         Messages
                //     </ToggleButton>
                //     <ToggleButton
                //         active={showFolders}
                //         onClick={() => setShowFolders(true)}
                //     >
                //         Folders
                //     </ToggleButton>
                // </ToggleWrapper>
            );
        }
    };

    const loadMoreFolders = () => {
        if (findFolderData?.findFolders?.folders) {
            loadMoreFilteredFolders(
                '', // empty string since we're not searching
                findFolderData.findFolders.folders,
                fetchMoreFolders,
                {
                    loadingMore: loadingOlder,
                    setLoadingOlder,
                    setLoadMoreCheck
                },
                HISTORY_SORT_FIELD
            );
        }
    };


    // const loadContent = () => {
    //     if (findFolderLoading) {
    //         return <LoadingText>Loading...</LoadingText>;
    //     }

    //     return (
    //         <Folders id="foldersList" browser={Browser} platform={Platform}>

    //             {findFolderData?.findFolders?.folders.map((folder, index) => (
    //                 <div
    //                     id={folder.id}
    //                     key={folder.id}
    //                     style={{
    //                         transform: Browser === 'Firefox' && Platform === PLATFORMS.MACINTOSH ? 'scaleY(-1)' : 'scaleY(1)',
    //                         zIndex: -index
    //                     }}
    //                 >
    //                     <FolderItem
    //                         onClick={() => {
    //                             setSelectedFolder(folder);
    //                         }}
    //                         isSelected={selectedFolder?.id === folder.id}
    //                     >
    //                         {folder.title}
    //                     </FolderItem>
    //                     {renderWaypoint(index, findFolderData.findFolders.folders)}
    //                 </div>
    //             ))}
    //             {loadingOlder && (
    //                 <LoadingText browser={Browser} platform={Platform}>
    //                     Loading more folders...
    //                 </LoadingText>
    //             )}
    //         </Folders>
    //     );
    // };


    // if (data) {
    // let payload = {
    //     messages: data.findMessages.messages,
    //     cursor: data.findMessages.cursor
    // };
    const sliceFolders = findFolderData && [...findFolderData?.findFolders?.folders].slice(0, -1)
    return (
        <React.Fragment>
            <FolderWrapper
                style={
                    {
                        // background: `url(${background}) no-repeat center center fixed`,
                        // backgroundSize: 'cover',
                        // backgroundRepeat: 'no-repeat'
                        // backgroundPosition: 'center'
                    }
                }
                isSelecting={showMultiSelect}
            >
                <FolderHeader titleSize={16} titleEditable={false}></FolderHeader>
                <HideDeletedMessagesComponent />
                {!showFolders && data ? (
                    <MessagesList
                        messageFeatures={[SHOW_OPTIONS.SHOW_FOLDER]}
                        payload={{
                            folders: sliceFolders,
                            messages: data.findMessages.messages,
                            cursor: data.findMessages.cursor,
                            sortBy: HISTORY_SORT_FIELD
                        }}
                        isTrash={showDeletedMessages}
                        noFolderSort
                        filterMessages={filterData && filterData.filterMessages.messages}
                        filterMessagesCursor={filterData && filterData.filterMessages.cursor}
                        fetchFindMore={fetchMore}
                        filterFetchMore={filterFetchMore}
                        selectMessage={selectMessage}
                        enableShiftSelect={enableShiftSelect}
                        selectedMessagesMap={selectedMessagesMap}
                        screenName={'FolderLastViewed'}
                    />
                ) : null}
                {/* {showFolders && (
                    loadContent()
                )} */}

                {renderInputOrMultiSelect()}
            </FolderWrapper>
            <SearchFoldersModal
                isOpen={showFoldersModal}
                title={`Move ${messagesToSend.length} ${pluralHelper(messagesToSend.length, 'message')} to...`}
                addNewMoveMessage
                closeModal={() => {
                    setShowFoldersModal(false);
                }}
            ></SearchFoldersModal>
        </React.Fragment>
    );
    // }
    // return null;
    // else {
    //     if (loading) {
    //         return renderLoadingScreen(`Loading all messages...`);
    //     } else {
    //         return null;
    //     }
    // }
}

const FolderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: ${PRIMARY_COLOR};
    position: relative;
    ${props => props.isSelecting && `
        user-select: none;
    `}
`;

const FolderTitleWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const H3 = styled.h3`
    color: white;
`;

const Messages = styled.div`
    grid-column: 3;
    grid-row: 2;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    flex: 1;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
`;


const ToggleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0;
    gap: 10px;
`;

const ToggleButton = styled.button`
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid ${PALE_WHITE_2};
    background: ${props => props.active ? PRIMARY_COLOR : PRIMARY_COLOR_6};
    color: ${props => props.active ? WHITE : PALE_WHITE_6};
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background: ${PRIMARY_COLOR};
        color: ${WHITE};
    }
`;

const Folders = styled.div`
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
    flex-direction: column-reverse;
    transform: scaleY(1);
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            flex-direction: column;
            transform: scaleY(-1);
        `}
    padding-left: 10px;
    padding-right: 10px;
`;

const FolderItem = styled.div`
    padding: 12px;
    background: ${PRIMARY_COLOR_6};
    // background: ${props => props.isSelected ? PRIMARY_COLOR_6 : 'rgba(255, 255, 255, 0.1)'};
    border-radius: 4px;
    color: ${WHITE};
    margin-bottom: 8px;
    cursor: pointer;
    transform: scaleY(1);
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    backdrop-filter: saturate(180%) blur(20px);
    
    &:hover {
        background: ${PRIMARY_COLOR_4};
        color: ${PALE_WHITE_6};
        .shortcut {
            color: ${PALE_WHITE_4};
        }
    }

    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
        `}
`;

const LoadingText = styled.div`
    display: flex;
    justify-content: center;
    color: ${PALE_WHITE_6};
    padding: 16px;
    font-size: 14px;
    transform: scaleY(1);
    background: ${PRIMARY_COLOR_6};
    border-radius: 4px;
    backdrop-filter: saturate(180%) blur(20px);
    margin-bottom: 8px;
    
    ${props =>
        props.browser === 'Firefox' &&
        props.platform === 'Macintosh' &&
        css`
            transform: scaleY(-1);
        `}
`;
export default HierarchyFolderContainer;
