import React, { useState, useEffect, useRef } from 'react';
import { QUERY } from '../../../gql-operations';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import styled from 'styled-components';

import { SHOW_OPTIONS } from '../../Messages/DefaultMessage';
import SearchMessagesModal from '../../../components/Modals/SearchMessagesModal';
import SearchFoldersModal from '../../../components/Modals/SearchFoldersModal';

import MessagesList from '../Components/MessagesList';
import MultiSelectOptions from '../Components/MultiSelectOptions';
import FilterComponent from '../Components/FilterComponent';
import ScrollToBottomButton from '../Components/ScrollToBottomButton';
import FolderHeader from '../Components/FolderHeader';

// import useMoveMessagesMutation from '../../../hooks/useMoveMessagesMutation';

import useKeyboardListener from '../../../hooks/useKeyboardListener';

import { AuthContext } from '../../../util/context';
import { pluralHelper } from '../../../util/pluralHelper';
import HideDeletedMessagesComponent from '../HideDeletedMessagesComponent';

import useRedirectErrors from '../../../errors/useRedirectErrors';
import { PRIMARY_COLOR, PALE_WHITE_6, PRIMARY_COLOR_6 } from '../../../appearance/Colors';

import Loader from 'react-loader-spinner';
import customAnalytics from '../../../util/custom-analytics';
import { HISTORY } from '../../../util/specialFolderEnums';
import { DEFAULT_SORT_FIELD, HISTORY_SORT_FIELD } from '../../../constants/dateField';

const FOLDER_SCREENS = {
    HISTORY: 'FolderHistory',
    ALL: 'FolderAll'
};

function HierarchyFolderContainer({ enableShiftSelect = true }) {
    const {
        state: { selectedFolder, folderFilterType, showDeletedMessages, messagesToSend, selectedMessagesMap, showMultiSelect },
        context: {
            setSelectedMessage,
            setShowDeletedMessages,
            setMessagesToSend,
            setSelectedMessagesMap,
            setShowMultiSelect
        }
    } = React.useContext(AuthContext);

    // MODALS
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const { passingErrors } = useRedirectErrors();

    // const [selectedMessagesMap, setSelectedMessagesMap] = useState({});
    // const [messagesToSend, setMessagesToSend] = useState([]);

    // HOOKS
    // const [moveMessages] = useMoveMessagesMutation();

    // const {} = useKeyboardListener();

    const [findMessages, { data, error, loading, findMessagesLoading, fetchMore }] = useLazyQuery(QUERY.FIND_MESSAGES, {
        onError: res => {
            passingErrors(res);
        },
        fetchPolicy: 'network-only'
    });

    const [
        filterMessages,
        {
            loading: filterLoading,
            error: filterError,
            data: filterData,
            fetchMore: filterFetchMore,
            client,
            updateQuery
        }
    ] = useLazyQuery(QUERY.FILTER_MESSAGES, {
        onError: res => {
            passingErrors(res);
        },
        fetchPolicy: 'network-only'
    });

    const customTypeFilter = type => {
        filterMessages({
            variables: {
                data: {
                    text: '',
                    type: type,
                    folderId: '',
                    isTrash: showDeletedMessages,
                    sortBy: selectedFolder.id === HISTORY ? HISTORY_SORT_FIELD : DEFAULT_SORT_FIELD
                }
            }
        });
    };

    useEffect(() => {
        customAnalytics.pageview('/all_view', 'FolderAll');
    }, [selectedFolder]);

    useEffect(() => {
        findMessages({
            variables: {
                data: {
                    text: '',
                    folderId: '',
                    isTrash: showDeletedMessages,
                    sortBy: selectedFolder.id === HISTORY ? HISTORY_SORT_FIELD : DEFAULT_SORT_FIELD
                }
            }
        });
        return () => {
            // cleanup
        };
    }, [showDeletedMessages, selectedFolder]);

    useEffect(() => {
        if (folderFilterType.length > 0) {
            customTypeFilter(folderFilterType);
        }
        return () => {
            // cleanup
        };
    }, [selectedFolder, folderFilterType, showDeletedMessages]);

    // const removeSelectedMessages = () => {
    //     setSelectedMessagesMap({});
    //     setMessagesToSend([]);
    //     setShowMultiSelect(false);
    // };

    const sendMessageAction = (action, message, index) => {
        let actionObject = {}
        if (folderFilterType && folderFilterType.length > 0) {
            actionObject["filters_active"] = [...folderFilterType];
        }
        if (showDeletedMessages) {
            actionObject["show_trashed"] = true;
        }
        actionObject = {
            "action": action,
            "screen": 'FolderAll',
            "index_position": index,
            ...actionObject
        }
        console.log('this', actionObject)
        customAnalytics.message_action(actionObject, message);
    }


    const selectMessage = (message, listIndex, isShiftClick) => {


        if (enableShiftSelect && isShiftClick && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, listIndex);
            const end = Math.max(lastSelectedIndex, listIndex);
            const newSelectedMessagesMap = { ...selectedMessagesMap };
            let newMessagesToSend = [...messagesToSend];

            // Use the correct message array based on whether we're filtering
            const messages = folderFilterType.length > 0 && filterData
                ? filterData.filterMessages.messages
                : data.findMessages.messages;

            for (let i = start; i <= end; i++) {
                const msg = messages[i];
                if (msg && !newSelectedMessagesMap[msg.id]) {
                    newSelectedMessagesMap[msg.id] = true;
                    newMessagesToSend.push(msg);
                    sendMessageAction('Select', msg, i);
                }
            }

            setSelectedMessagesMap(newSelectedMessagesMap);
            setMessagesToSend(newMessagesToSend);
        } else {
            if (selectedMessagesMap[message.id]) {
                let newSpread = [...messagesToSend];
                let index = newSpread.findIndex(obj => obj.id === message.id);

                if (index > -1) {
                    newSpread.splice(index, 1);
                }

                setMessagesToSend([...newSpread]);

                const newSelectedMessagesMap = { ...selectedMessagesMap };
                delete newSelectedMessagesMap[message.id];
                setSelectedMessagesMap(newSelectedMessagesMap);
                sendMessageAction('Deselect', message, listIndex);
            } else {
                setSelectedMessagesMap({
                    ...selectedMessagesMap,
                    [message.id]: true
                });
                setMessagesToSend([...messagesToSend, message]);
                sendMessageAction('Select', message, listIndex);
            }
        }

        setLastSelectedIndex(listIndex);
        setShowMultiSelect(true);

    };

    const renderInputOrMultiSelect = () => {
        if (Object.keys(selectedMessagesMap).length > 0) {
            return (
                <MultiSelectOptions
                    // onDeselect={() => {
                    //     removeSelectedMessages();
                    // }}
                    onSelectMove={() => {
                        setShowFoldersModal(true);
                    }}
                ></MultiSelectOptions>
            );
        } else {
            return null;
        }
    };

    const renderLoadingScreen = title => {
        return (
            <div style={{ color: 'white', backgroundColor: PRIMARY_COLOR_6, flex: 1, padding: 24 }}>
                <Loader
                    type="Puff"
                    // color="#00BFFF"
                    color={PALE_WHITE_6}
                    height={50}
                    width={50}
                // timeout={3000} //3 secs
                />
                <div style={{ fontWeight: '600', color: PALE_WHITE_6, marginTop: 8 }}>{title}</div>
            </div>
        );
    };

    // if (data) {
    // let payload = {
    //     messages: data.findMessages.messages,
    //     cursor: data.findMessages.cursor
    // };
    return (
        <React.Fragment>
            <FolderWrapper
                style={
                    {
                        // background: `url(${background}) no-repeat center center fixed`,
                        // backgroundSize: 'cover',
                        // backgroundRepeat: 'no-repeat'
                        // backgroundPosition: 'center'
                    }
                }
                isSelecting={showMultiSelect}
            >
                <FolderHeader titleSize={16} titleEditable={false}></FolderHeader>
                <HideDeletedMessagesComponent />
                {data ? (
                    <MessagesList
                        messageFeatures={[SHOW_OPTIONS.SHOW_FOLDER]}
                        payload={{
                            messages: data.findMessages.messages,
                            cursor: data.findMessages.cursor,
                            sortBy: selectedFolder.id === HISTORY ? HISTORY_SORT_FIELD : DEFAULT_SORT_FIELD
                        }}
                        isTrash={showDeletedMessages}
                        filterMessages={filterData && filterData.filterMessages.messages}
                        filterMessagesCursor={filterData && filterData.filterMessages.cursor}
                        fetchFindMore={fetchMore}
                        filterFetchMore={filterFetchMore}
                        selectMessage={selectMessage}
                        enableShiftSelect={enableShiftSelect}
                        selectedMessagesMap={selectedMessagesMap}
                        screenName={'FolderAll'}
                    />
                ) : null}
                {renderInputOrMultiSelect()}
            </FolderWrapper>
            <SearchFoldersModal
                isOpen={showFoldersModal}
                title={`Move ${messagesToSend.length} ${pluralHelper(messagesToSend.length, 'message')} to...`}
                addNewMoveMessage
                closeModal={() => {
                    setShowFoldersModal(false);
                }}
            ></SearchFoldersModal>
        </React.Fragment>
    );
    // }
    // return null;
    // else {
    //     if (loading) {
    //         return renderLoadingScreen(`Loading all messages...`);
    //     } else {
    //         return null;
    //     }
    // }
}

const FolderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: ${PRIMARY_COLOR};
    position: relative;
    ${props => props.isSelecting && `
        user-select: none;
    `}
`;

const FolderTitleWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
`;

const H3 = styled.h3`
    color: white;
`;

const Messages = styled.div`
    grid-column: 3;
    grid-row: 2;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    flex: 1;
`;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    margin-bottom: 14px;
`;

export default HierarchyFolderContainer;
