import { QUERY } from '../../../gql-operations';
import { DateField } from '../../../constants/dateField';

async function loadMoreFilteredFolders(
    text,
    folders,
    fetchMore,
    {
        loadingMore,
        setLoadingOlder,
        setLoadMoreCheck
    },
    sortBy = DateField.CREATED_AT
) {
    // Skip if already loading
    if (loadingMore) {
        return;
    }

    try {
        setLoadingOlder(true);
        const cursor = folders[folders.length - 1]?.[sortBy];

        const result = await fetchMore({
            query: QUERY.FIND_FOLDERS,
            variables: {
                data: {
                    text: text || '',
                    cursor,
                    sortBy
                }
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult?.findFolders?.folders) {
                    setLoadMoreCheck(false);
                    return previousResult;
                }

                const previousFolders = previousResult.findFolders.folders;
                const newFolders = fetchMoreResult.findFolders.folders;

                // Filter out duplicate folders
                const existingIds = new Set(previousFolders.map(folder => folder.id));
                const uniqueNewFolders = newFolders.filter(folder => !existingIds.has(folder.id));

                if (uniqueNewFolders.length === 0) {
                    setLoadMoreCheck(false);
                    return previousResult;
                }

                // Combine and sort folders
                let allFolders = [...previousFolders, ...uniqueNewFolders];
                allFolders.sort((a, b) => new Date(b[sortBy]) - new Date(a[sortBy]));

                // Check if we should continue showing the load more trigger
                setLoadMoreCheck(uniqueNewFolders.length >= 10); // Assuming page size is 10

                return {
                    findFolders: {
                        folders: allFolders,
                        cursor: fetchMoreResult.findFolders.cursor,
                        __typename: previousResult.findFolders.__typename
                    }
                };
            }
        });

        return result;
    } catch (error) {
        console.error('Error loading more folders:', error);
        setLoadMoreCheck(false);
    } finally {
        setLoadingOlder(false);
    }
}

export default loadMoreFilteredFolders;