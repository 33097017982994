import React from 'react';

import { PRIMARY_COLOR_6, WHITE, PALE_WHITE_2, PALE_WHITE_4, PALE_WHITE_6, PALE_WHITE } from '../appearance/Colors';

import styled from 'styled-components';

import { IoMdOpen, IoMdSearch } from 'react-icons/io';
import { AuthContext } from '../util/context';
// import { SEARCH_MESSAGES } from '../util/specialFolderEnums';

function SearchInputButton({ placeholder, tip, hideFullScreen, hideSearch, ...rest }) {
    const {
        state: { selectedFolder },
        context: { setSearchActive }
    } = React.useContext(AuthContext);

    return (
        <SearchInput
            style={{
                paddingRight: 12,
                paddingLeft: 12,
                height: 40,
                borderRadius: 12,
                backgroundColor: PRIMARY_COLOR_6,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                margin: 8,
                minWidth: 200,
                cursor: 'pointer',
                justifyContent: 'center',
                backdropFilter: 'saturate(180%) blur(20px)'
            }}
            {...rest}
        >
            {!hideSearch ? <IoMdSearch size={16} style={{ marginRight: 4, color: PALE_WHITE_6 }} /> : null}
            <span style={{ lineHeight: '16px', color: PALE_WHITE_6 }}>{placeholder}</span>
            <span style={{ flex: 1 }}></span>
            <TextWrapper className="shortcut">
                <span>{tip}</span>
            </TextWrapper>
            {!hideFullScreen ? (
                <Fullscreen
                    onClick={e => {
                        e.stopPropagation();
                        // console.log('why', selectedFolder);
                        // setSelectedFolder(selectedFolder);
                        setSearchActive(true);
                    }}
                >
                    <IoMdOpen size={16} style={{}} />
                </Fullscreen>
            ) : null}
        </SearchInput>
    );
}

const SearchInput = styled.div`
    &:hover {
        .shortcut {
            color: ${PALE_WHITE_4};
        }
    }
`;

const Fullscreen = styled.div`
    padding: 4px;
    border-radius: 4px;
    // border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
        .shortcut {
            color: ${PALE_WHITE_4};
        }
    }
`;

const TextWrapper = styled.div`
    // padding: 2px;
    color: transparent;
    margin-left: 4px;
`;

export default SearchInputButton;
